import React, { useState } from "react";
import config_ip from '../../../config_ip';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button.jsx';
import { Input } from "@/components/ui/input.jsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import { FaSave } from "react-icons/fa";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useAuth } from "@clerk/clerk-react";
import { useAllFetch } from '../../CustomHooks/useAllFetch';
import { Loading } from "../../UI/Loading";

const contractorId = window.location.pathname.split('/')[2];
const contractor_id = localStorage.getItem('temp')

export default function EditContractor() {

  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)
  const [zipCode, setzipCode] = useState('')
  const [address2, setAddress2] = useState('')
  const { getToken } = useAuth()

  const editContractor = useAllFetch(['editcontractor'], '/getcontractor/' + contractorId)

  const handleChange = address => {
    setAddress2(address)
  }

  const handleSelect = async address => {
    setAddress2(address)
    const results = await geocodeByAddress(address)
    let zcode = results[0].address_components[results[0].address_components.length - 1].long_name.replace(' ', '')
    setzipCode(zcode)
    const latLng = await getLatLng(results[0])
    setLat(latLng.lat)
    setLng(latLng.lng)
  }

  const onSubmit = async (data) => {
    const tokenu = await getToken()
    const resp = await fetch(config_ip.url + "/updateContractor/" + contractorId, {
      method: "put",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenu}`
      },
    });
    if (resp.ok) {
      window.location.pathname = "/lcontractors";
    } else {
      console.log("Error Contractor updating")
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ["ca", "us"] }
  }

  const contractorSchema = object().shape({
    name: string()
    .min(6),
    email: string()
      .max(150),
    phone: string()
      .min(7,"Too Short!")
      .max(13,"Too Long!"),
    contact: string()
      .min(10),
    status: string(),
  })

  const form = useForm({
    resolver: yupResolver(contractorSchema),
    values: {
      // id: editContractor.data?.id,
      address: address2 ? address2 : editContractor.data?.address,
      name: editContractor.data?.name,
      email: editContractor.data?.email,
      phone: editContractor.data?.phone,
      contact: editContractor.data?.contact,
      status: editContractor.data?.status,
      logo: editContractor.data?.logo,
      lat: lat ? lat : editContractor.data?.lat,
      lng: lng ? lng : editContractor.data?.lng,
      zipcode: zipCode,
    }
  })

  if (editContractor.isLoading) {
    return <Loading />;  }

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={"/lcontractors"}>List Contractors</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Edit Contractor</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <PlacesAutocomplete
        value={address2}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div >
            <input
              {...getInputProps({
                placeholder: 'Search Address ...',
                className: 'location-search-input',
              })}
              autoFocus={true}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading ...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active ? 'suggestion-item-active' : 'suggestion-item'
                const style = suggestion.active ? { backgroundColor: '#65bdb1', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  <div {...getSuggestionItemProps(suggestion, { className, style })} key={suggestion.index}>
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="address"
            readOnly
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter address on the previous field"
                    {...field}
                    {...form.register("address")}
                    value={field.value}
                    readOnly={true}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter contractor name"
                    {...field}
                    {...form.register("name")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            type="email"
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter email"
                    {...field}
                    {...form.register("email")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter number phone"
                    {...field}
                    {...form.register("phone")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="contact"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Contact</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter name contact"
                    {...field}
                    {...form.register("contact")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Status</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select Robbie Id' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='A'>Active</SelectItem>
                      <SelectItem value='I'>Inactive</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit"><FaSave></FaSave></Button>
        </form>
      </Form>
    </div>
  )
}