import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { CSVLink } from "react-csv";
import "./linechart.scss";
import Plot from "react-plotly.js";
import ip from "../config_ip";
import Viewbox from "./UI/Viewbox";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form.jsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter } from '@/components/ui/dialog.jsx';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent
} from '@/components/ui/tabs.jsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { Button } from "@/components/ui/button.jsx";
import { Input } from "@/components/ui/input.jsx";
import Telemetrics from "./UI/AreaTelemetrics";
import Allfetch from "./Allfetch";
import { useAllFetch } from "./CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { Loading } from "./UI/Loading";
import NoResults from "./UI/NoResults";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.jsx";
import { useTranslation, Trans } from "react-i18next";

const user = localStorage.getItem('name')
const arraySlash = window.location.pathname.split('/')
const jobId = arraySlash[2]
const areaId = arraySlash[3]

export default function Linegraph(props) {

  const { t } = useTranslation()
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };

  const [veriEmail, setVeriEmail] = useState("")
  const [areaPictures, setAreaPictures] = useState([])
  const [loading, setLoading] = useState(false)
  const [isDataSampled, setIsDataSampled] = useState(false)
  const fileInputRef = useRef();
  const { getToken } = useAuth()

  const area = useAllFetch(['area'], '/getarea/' + areaId)
  const events = useAllFetch(['events'], '/getevents/' + areaId)
  const daily = useAllFetch(['daily'], '/daily/' + jobId + '/' + areaId)
  const current = useAllFetch(['current'], '/current/' + jobId + '/' + areaId)

  const changeStatusF = async (status, jobid, areaid, currentener, nada, why) => {
    const tokenu = await getToken();
    let type;
    let why1;
    if (why === undefined && status !== 'F') {
      type = 'R';
      why1 = 'Restart area';
    } else {
      type = status;
      why1 = why;
    }
    if (status !== 'F') {
      await fetch(ip.url + "/events", {
        method: "post",
        body: JSON.stringify({ areaid: areaid, why: why1, user: user, type: type }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      });
    }
    var response = await fetch(ip.url + "/changeStatus/" + status + "/" + jobid + "/" + areaid, {
        method: "get",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`,
      }
    });
    response = await response.json();
    
    // Always redirect to the areas list page after pausing or finishing an area
    if (response.claims === 'finish') {
      // For claim finishing, we still need to send mail before redirecting
      setVeriEmail(response.data?.email);
      await sendMail(jobid, areaid, response.data?.address, response.data?.email, currentener, response.data?.emailc);
    }
    
    // Redirect to the areas list page for the current job
    window.location.pathname = "/lareas/" + jobid;
  };

  const fetchPictures = useCallback(async () => {
    const tokenu = await getToken()
    try {
      const response = await fetch(ip.url + "/getPictures/" + jobId + "/" + areaId, {
        method: 'GET',
        headers: {
          'Authorization': `bearer ${tokenu}`
        }
      });
      const result = await response.json();
      setAreaPictures(result.data);
    } catch (error) {
      console.error("Error fetching claim pictures:", error);
    }
  }, [getToken]);

  useEffect(() => {
    fetchPictures()
  }, [fetchPictures])

  // Update isDataSampled state when data changes
  useEffect(() => {
    if (daily.data && daily.data.length > 0) {
      // Calculate how many data points are needed for 3.2 days of minute-by-minute logs
      // 3.2 days * 24 hours * 60 minutes = 4608 data points
      const MIN_FULL_RESOLUTION_POINTS = 4608;
      
      setIsDataSampled(daily.data.length > MIN_FULL_RESOLUTION_POINTS);
    }
  }, [daily.data]);

  const sendMail = async (idjob, areaid, address, emailc, currentener, contractor_email) => {
    Allfetch('/send-email/' + idjob + "/" + areaid + "/" + address + "/" + emailc + "/" + currentener + "/" + contractor_email + "/" + navigator.language.split('-',1)[0], veriEmail, null, null, await getToken())
  }

  const handleDelete = (deletedFile) => {
    setAreaPictures(areaPictures.filter((file) => file.filename !== deletedFile.filename));
  };

  const uploadPicture = async (file) => {
    const tokenu = await getToken()
    const formData = new FormData();
    formData.append("picture", file);
    try {
      const response = await fetch(ip.url + "/uploadPicture/" + jobId + "/" + areaId, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${tokenu}`
        }
      });

      await response.json();
      //console.log(result);
      fetchPictures();
      setLoading(false)
    } catch (error) {
      console.error("Error uploading picture:", error);
    }
  };

  const handleFileChange = (e) => {
    setLoading(true)
    const file = e.target.files[0];
    if (file) {
      uploadPicture(file);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  // Create a memoized version of the processed data to avoid recalculations
  const processedData = useMemo(() => {
    if (!daily.data) return { datedata: [], humidata: [], tempdata: [], amperdata: [], powerdata: [], energydata: [], moisturedata: [], firstdate: null, lastdate: null };
    
    const data = daily.data;
    const result = {
      datedata: [],
      humidata: [],
      tempdata: [],
      amperdata: [],
      powerdata: [],
      energydata: [],
      moisturedata: [],
      firstdate: new Date(data[0].date + 'Z'),
      lastdate: new Date(data[data.length - 1].date + 'Z')
    };
    
    // Calculate how many data points are needed for 3.2 days of minute-by-minute logs
    const MIN_FULL_RESOLUTION_POINTS = 4608;
    
    let sampledData = data;
    
    if (data.length > MIN_FULL_RESOLUTION_POINTS) {
      // Get the timestamp for 3.2 days ago from the most recent data point
      const mostRecentDate = new Date(data[data.length - 1].date + 'Z');
      const thresholdDate = new Date(mostRecentDate);
      thresholdDate.setDate(thresholdDate.getDate() - 3.2);
      
      // Split the data into recent (full resolution) and older (to be sampled)
      const recentData = [];
      const olderData = [];
      
      for (let i = 0; i < data.length; i++) {
        const currentDate = new Date(data[i].date + 'Z');
        if (currentDate >= thresholdDate) {
          recentData.push(data[i]);
        } else {
          olderData.push(data[i]);
        }
      }
      
      // Sample the older data if there's any
      let sampledOlderData = [];
      if (olderData.length > 0) {
        const MAX_OLDER_POINTS = 500; // Limit for older data points
        const samplingRate = Math.ceil(olderData.length / MAX_OLDER_POINTS);
        sampledOlderData = olderData.filter((_, index) => index % samplingRate === 0);
        
        // Always include the first point from older data
        if (sampledOlderData.length > 0 && sampledOlderData[0] !== olderData[0]) {
          sampledOlderData.unshift(olderData[0]);
        }
      }
      
      // Combine the sampled older data with the full resolution recent data
      sampledData = [...sampledOlderData, ...recentData];
      
      // Set the state to indicate data is sampled
      setIsDataSampled(true);
    } else {
      setIsDataSampled(false);
    }
    
    // Process the sampled data
    for (let i = 0; i < sampledData.length; i++) {
      const item = sampledData[i];
      if (item) {
        const fechaLocal = new Date(item.date + 'Z');
        result.datedata.push(fechaLocal);
        result.humidata.push(item.humi);
        result.tempdata.push(item.temp);
        result.amperdata.push(item.amper_ef);
        result.powerdata.push(item.power / 1000);
        result.energydata.push(item.energy);
        result.moisturedata.push(item.moisture);
      }
    }
    
    return result;
  }, [daily.data]);

  // Use the processed data in the component
  let { datedata, humidata, tempdata, amperdata, firstdate, lastdate } = processedData;

  let timeelapsed = 0;

  let currenttemp = 0;
  let currenthumi = 0;
  let currentamper = 0;
  let currentpower = 0;
  let currentenergy = 0;

  let currentdate = "";
  let setpoint = 0.0;
  let amperage_expected = 0.0;
  let date_drying = "";

  let RH = 0;
  let t1 = 0;
  let H = 0;
  let dewpoint = 0;
  
  // Wrap pausedate and pausewhy in useMemo to fix dependency warnings
  const { pausedate, pausewhy } = useMemo(() => {
    const pausedate = [];
    const pausewhy = [];
    
    if (daily.data !== undefined && events !== undefined) {
      for (let j = 0; j < events.data?.length; j = j + 1) {
        if (events.data[j].why !== 'Restart area') {
          const fechaLocal = new Date(events.data?.[j].created_at + 'Z');
          pausedate.push(fechaLocal);
          pausewhy.push(events.data?.[j].why);
        }
      }
    }
    
    return { pausedate, pausewhy };
  }, [daily.data, events]);
  
  // use the react query hook area to get the data from the api
  // wait for the data to be fetched before rendering the component
  // if the data is not fetched yet, show the loading componen
  if (!area.isLoading) {
    setpoint =  area.data?.humisetpoint
    amperage_expected = area.data?.amperage_expected
    date_drying = area.data?.date_drying
  }
  
  if (current !== undefined) {
    const data1 = current.data?.[0]
    if (data1 !== undefined) {
      currenttemp = data1.temp;
      currenthumi = data1.humi;
      currentamper = data1.amper_ef.toFixed(2);
      currentenergy = data1.energy.toFixed(2);
      currentpower = data1.power.toFixed(2);
      const dateUTC = new Date(data1.date + 'Z')
      currentdate = dateUTC.toLocaleString(
        "en-CA",
        options
      );
      RH = currenthumi
      t1 = currenttemp
      H = (Math.log10(RH) - 2.0) / 0.4343 + (17.62 * t1) / (243.12 + t1);
      dewpoint = (243.12 * H / (17.62 - H)).toFixed(2);
    }
  }

  timeelapsed = timeConversion(Date.parse(lastdate) - Date.parse(firstdate))

  function timeConversion(millisec) {
    //var seconds = (millisec / 1000).toFixed(1);
    var minutes = (millisec / (1000 * 60.0)).toFixed(3);
    var hours = (millisec / (1000 * 60.0 * 60.0)).toFixed(3);
    var days = (millisec / (1000 * 60.0 * 60.0 * 24)).toFixed(3);

    let realdays = 0
    let realhours = 0
    let realminutes = 0
    if (days > 1) {
      realdays = days.toString().split('.')[0];
      hours = days.toString().split('.')[1] * 2.4 / 100
    }
    if (hours < 24) {
      realhours = hours.toString().split('.')[0];
      hours = parseFloat(hours).toFixed(3)
      minutes = hours.toString().split('.')[1] * 6.0 / 100
    }
    if (minutes < 60) {
      realminutes = minutes.toString().split('.')[0];
    }
    if (realdays < 1) {
      return realhours + " Hours " + realminutes + " Minutes"
    } else {
      return realdays + " Days " + realhours + " Hours " + realminutes + " Minutes"
    }
  }

  // Wrap humidity_traces in useMemo to fix dependency warning
  const humidity_traces = useMemo(() => {
    const traces = [
      {
        name: "Relative Humidity (%)",
        x: datedata,
        y: humidata,
        mode: "lines",
        line: { shape: "spline" },
        type: "scatter",
        smoothing: 1.3,
        marker: { color: "#54DED0" },
        hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>%{y:.1f}%<extra>Relative Humidity</extra>'
      },
      {
        name: "Relative Humidity Shadow",
        showlegend: false,
        x: datedata,
        y: humidata,
        mode: "lines",
        line: { shape: "spline", width: 10 },
        type: "scatter",
        smoothing: 1.3,
        marker: { color: "#54ded033" },
        hoverinfo: 'skip'
      },
      {
        name: "Temperature (°C)",
        x: datedata,
        y: tempdata,
        mode: "lines",
        line: { shape: "spline" },
        type: "scatter",
        smoothing: 1.3,
        marker: { color: "#ff76004f" },
        hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>%{y:.1f}°C<extra>Temperature</extra>'
      },
      {
        name: "Temperature Shadow(°C)",
        showlegend: false,
        x: datedata,
        y: tempdata,
        mode: "lines",
        line: { shape: "spline", width: 10 },
        type: "scatter",
        smoothing: 1.3,
        marker: { color: "#ff760014" },
        hoverinfo: 'skip'
      },
      {
        name: "Setpoint (%)",
        x: [firstdate, lastdate],
        y: [setpoint, setpoint],
        mode: "lines",
        type: "scatter",
        line: {
          dash: "dot",
          width: 1,
        },
        marker: { color: "#DDDDDD" },
        hovertemplate: 'Setpoint: %{y:.1f}%<extra>Setpoint</extra>'
      },
      {
        name: "Electrical Current (A)",
        x: datedata,
        y: amperdata,
        mode: "lines",
        line: { shape: "linear" },
        smoothing: 1.3,
        type: "scatter",
        marker: { color: "#F2D027" },
        hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>%{y:.2f}A<extra>Electrical Current</extra>'
      },
      {
        name: "Electrical Current Shadow (A)",
        showlegend: false,
        x: datedata,
        y: amperdata,
        mode: "lines",
        line: { shape: "linear", width: 10 },
        smoothing: 1.3,
        type: "scatter",
        marker: { color: "#f2d02742" },
        hoverinfo: 'skip'
      }
    ];
    
    // Add pause traces
    for (let i = 0; i < pausedate.length; i++) {
      traces.push({
        name: "Paused",
        // Only show the legend for the first pause event
        showlegend: i === 0,
        x: [pausedate[i], pausedate[i]],
        y: [1, 90],
        mode: "lines+markers",
        line: {
          dash: "dot",
          width: 2,
        },
        type: "scatter",
        marker: { color: "black" },
        text: pausewhy[i],
        textposition: "middle right",
        hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>' + pausewhy[i] + '<extra>Paused</extra>'
      });
    }
    
    // Add prediction times if available
    if (area.data?.prediction_times && area.data.prediction_times.length > 0) {
      // Create a trace for each prediction time
      for (let i = 0; i < area.data.prediction_times.length; i++) {
        const predictionDate = new Date(area.data.prediction_times[i].timestamp + 'Z');
        traces.push({
          name: "AI dry prediction",
          // Only show the legend for the first prediction time
          showlegend: i === 0,
          x: [predictionDate, predictionDate],
          y: [1, 90],
          mode: "lines",
          line: {
            dash: "solid",
            width: 1,
          },
          type: "scatter",
          marker: { color: "rgba(160, 160, 220, 0.4)" }, // More subtle, semi-transparent blue-purple
          hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>AI Predicted Dry Time<extra>AI Prediction</extra>'
        });
      }
    }
    
    return traces;
  }, [datedata, humidata, tempdata, amperdata, firstdate, lastdate, setpoint, pausedate, pausewhy, area.data?.prediction_times]);

  const whySchema = object().shape({
    why: string().max(250).required(),
  })
    
  const form = useForm({
    resolver: yupResolver(whySchema),
    values: {
      why: ''
    }
  })

  const onWhy = (data) => {
    changeStatusF("P", jobId, area.data?.id, currentenergy, timeelapsed, data.why)
  }

  // Wrap plotLayout in useMemo to fix dependency warning
  const plotLayout = useMemo(() => ({
    margin: {
      l: 50,
      r: 60,
      b: 50,
      t: 50,
      pad: 4
    },
    yaxis: { range: [0, 100], color: '#9E9E9E' },
    xaxis: {
      autorange: true,
      color: '#9E9E9E',
      // Only keep hoverformat for tooltips
      hoverformat: '%b %d, %Y, %I:%M:%S %p'
    },
    showlegend: true,
    legend: {
      bgcolor: 'rgba(0,0,0,0)',
      itemclick: false,
      itemdoubleclick: false,
      x: 1,
      xanchor: 'right',
    },
    annotations: isDataSampled ? [
      {
        text: 'Data older than 3.2 days is sampled for performance',
        showarrow: false,
        xref: 'paper',
        yref: 'paper',
        x: 0.98,
        y: 0.02,
        xanchor: 'right',
        yanchor: 'bottom',
        font: {
          size: 10,
          color: '#9E9E9E'
        },
        bgcolor: 'rgba(255, 255, 255, 0.7)',
        bordercolor: '#ddd',
        borderwidth: 1,
        borderpad: 4,
        align: 'right'
      }
    ] : []
  }), [isDataSampled]);

  // Memoize the Plot component to prevent unnecessary re-renders
  const MemoizedPlot = useMemo(() => (
    <Plot
      data={humidity_traces}
      layout={plotLayout}
      useResizeHandler
      style={{ width: "100%", height: "100%" }}
      config={{
        responsive: true,
        displayModeBar: true,
        scrollZoom: true,
        modeBarButtonsToRemove: ['lasso2d', 'select2d'],
        displaylogo: false,
        // Ensure local timezone is used for date display
        locales: {
          'en-US': {
            format: {
              days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
              shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              date: '%b %e, %Y',
              datetime: '%b %e, %Y, %I:%M:%S %p',
              hours: '%I %p',
              minutes: '%I:%M %p',
              seconds: '%I:%M:%S %p'
            }
          }
        },
        locale: 'en-US'
      }}
    />
  ), [humidity_traces, plotLayout]);

  if ( daily.isPending) {
    return <Loading />; 
  }

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-2">{area.data?.aname} | {area.data?.name_raspi}</div>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">{t('Claims')}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
            {arraySlash[2].length === 64 ?
              <BreadcrumbLink href={"/linktemp/" + arraySlash[2]}>{t('Areas')}</BreadcrumbLink>
              :
              <BreadcrumbLink href={"/lareas/" + jobId}>{t('Areas')}</BreadcrumbLink>
            }
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>{t('Area')}</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>


      <Tabs defaultValue="monitoring">
        <TabsList>
          <TabsTrigger value="monitoring">{t('Monitoring')}</TabsTrigger>
          <TabsTrigger value="documents">{t('Documentation')}</TabsTrigger>
        </TabsList>
        { currentamper < amperage_expected - 1 && date_drying !== '1970-01-01 00:00:00' ?   <Alert variant="destructive" className="mt-4 flex max-h-screen w-full flex-col p-4 sm:bottom-0 sm:left-0 sm:top-auto sm:flex-col" >
        <AlertTitle>{t('LowAmperageWarning')}</AlertTitle>
        <AlertDescription>
          <Trans i18nKey={"MessageLowAmperage"}>
            The real amperage is below the expected value for the type and amount of equipment in the area. please verify the equipment.
            we are expecting {{amperage_expected}} A and the current amperage is {{currentamper}} A
          </Trans>
        </AlertDescription>
      </Alert> : ''}
        <TabsContent value="monitoring">
          <div className="tab-container">
            <div className="plot-1">
              {MemoizedPlot}
            </div>

            <div className="text-muted-foreground text-sm italic mb-2">
              {area.data?.description} 
            </div>
            <Telemetrics
              current_humidity={currenthumi}
              current_temperature={currenttemp}
              current_dewpoint={parseFloat(dewpoint)}
              current_amperage={parseFloat(currentamper)}
              current_power={parseFloat(currentpower)}
              current_energy={parseFloat(currentenergy)}
              timeelapsed={timeelapsed}
              last_date_updated={currentdate}
              airmovers={area.data?.blowers}
              dehumidifiers={area.data?.dh}
            />

            <div className="space-x-4">
              {user !== null && area.data?.status !== "P" && area.data?.status !== "F" ? (
                <>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button>{t('Pause')}</Button>
                    </DialogTrigger>
                    <DialogContent>
                      <Form {...form}>
                        <form onSubmit={form.handleSubmit(onWhy)}>
                        <DialogHeader>
                          <DialogTitle>{t('Pause')}</DialogTitle>
                          <DialogDescription />
                        </DialogHeader>
                          <FormField
                            control={form.control}
                            name="why"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel />
                                <FormControl>
                                  <Input
                                    placeholder={t('Why')}
                                    {...field}
                                    {...form.register("why")}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        <DialogFooter>
                          <Button type='submit' disabled={form.formState.isSubmitSuccessful}>{t('Send')}</Button>
                        </DialogFooter>
                        </form>
                      </Form>
                    </DialogContent>
                  </Dialog>
                </>
              ) : ''}

              {user !== null && area.data?.status === "P" ?
                <Button onClick={() => changeStatusF("I", jobId, area.data?.id)}>{t('Unpause')}</Button>
                : ''
              }

              {user !== null && area.data?.status !== "F" ?
                <Button variant="finish" onClick={() => changeStatusF("F", jobId, area.data?.id, currentenergy, timeelapsed)}>{t('Finish')}</Button>
                : ''
              }

              {user !== null && area.data?.status === "F" ?
                <CSVLink data={daily.data} filename={"Job.csv"}>
                  <Button>{t('Download')}</Button>
                </CSVLink>
                : ''}
            </div>
          </div>

        </TabsContent>
        <TabsContent value="documents">
          {!loading ?
            <>
              <div className="claim-pictures">
                {areaPictures.length > 0 ? (
                  areaPictures.map((file) => (
                    <Viewbox
                      key={file.filename}
                      file={file}
                      jobid={jobId}
                      onDelete={handleDelete}
                    />
                  ))
                ) : (
                  <NoResults message={t('NoAreaPicture')} />
                )}
              </div>
              <div className="w-full text-center mt-2">
                <Button onClick={handleClick}>{t('AddAreaDocsPics')}</Button>
              </div>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </>
            :
            <div className="flex-div">
              <Loading />
              <div>
                <h3>Loading file ...</h3>
              </div>
            </div>
          }
        </TabsContent>
      </Tabs>
      <div>
    </div>
    </div>
  )
}
