"use client"

import { MoreHorizontal } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

export const columns = ({ t }) => [
  {
    accessorKey: "id",
    header: t("ID"),
  },
  {
    accessorKey: "name",
    header: t("Name"),
    cell: ({ row }) => {
      return <div className="font-medium">{row.getValue("name")}</div>
    },
  },
  {
    accessorKey: "address",
    header: t("Address"),
  },
  {
    accessorKey: "email",
    header: t("Email"),
  },
  {
    accessorKey: "phone",
    header: t("Phone"),
  },
  {
    accessorKey: "contact",
    header: t("Contact"),
  },
  {
    accessorKey: "status",
    header: t("Status"),
    filterFn: (row, id, value) => {
      return value === null || row.getValue(id) === value
    },
    cell: ({ row }) => {
      const status = row.getValue("status")
      
      return (
        <Badge 
          variant={status === "A" ? "success" : "destructive"}
          className="capitalize"
        >
          {status === "A" ? t("Active") : t("Inactive")}
        </Badge>
      )
    },
  },
  {
    accessorKey: "logo",
    header: t("Logo"),
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const contractor = row.original

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">{t("OpenMenu")}</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t("Actions")}</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => window.location.href = `/econtractor/${contractor.id}`}>
              {t("EditContractor")}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => window.location.href = `/assigninsu/${contractor.id}/${encodeURIComponent(contractor.name)}`}>
              {t("AssignInsurance")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => window.location.href = `/assignrobbie/${contractor.id}/${encodeURIComponent(contractor.name)}`}>
              {t("AssignRobbies")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
] 