import React, { useState, useRef, useCallback, useMemo } from "react";
import ip from "../../config_ip";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@/components/ui/tabs.jsx";
import { Button } from "@/components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import Viewbox from "../UI/Viewbox";
import { Loading } from "../UI/Loading";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { NoResults } from "../UI/NoResults";
import { useTranslation } from "react-i18next";
import { DataTable } from "./table/data-table";
import { columns } from "./table/columns";
import Allfetch from '../Allfetch';

const para = window.location.pathname.split("/");
const jobId = para[2];
const contractor_id = localStorage.getItem("contractor_id");
const name = localStorage.getItem('name');

export default function Areas() {
  const { t } = useTranslation();
  const [claimPictures, setClaimPictures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("areas");
  const [picturesLoaded, setPicturesLoaded] = useState(false);
  const fileInputRef = useRef();
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('status');
  const [sortOrder, setSortOrder] = useState('asc');
  const [statusR, setStatusR] = useState([]);

  const areasByJob = useAllFetch(["areasbyjob"], "/areasbyjob/" + jobId);
  const { getToken } = useAuth();

  const changeStatusF = useCallback(async (status, jobid, areaid) => {
    if (status === 'I') {
      const tokenu = await getToken()
      await fetch(ip.url + "/events", {
        method: "post",
        body: JSON.stringify({ areaid: areaid, why: "Restart area", user: name, type: "R" }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
    }
    Allfetch('/changeStatus/' + status + '/' + jobid + '/' + areaid, setStatusR, null, null, await getToken())
    if (statusR !== undefined) {
      window.location.pathname = "/jobs/" + contractor_id
    }
  }, [getToken, statusR]);

  const fetchPictures = useCallback(async () => {
    setLoading(true);
    const token = await getToken();
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(
        ip.url + "/getPictures/" + jobId + "/" + 0,
        options
      );
      const result = await response.json();
      setClaimPictures(result.data);
    } catch (error) {
      console.error("Error fetching claim pictures:", error);
    } finally {
      setLoading(false);
      setPicturesLoaded(true);
    }
  }, [getToken]);

  const handleTabChange = (value) => {
    setActiveTab(value);
    if (value === "documents" && !picturesLoaded) {
      fetchPictures();
    }
  };

  const handleDelete = (deletedFile) => {
    setClaimPictures(
      claimPictures.filter((file) => file.filename !== deletedFile.filename)
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadPicture(file);
    }
  };

  const uploadPicture = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("picture", file);
    const token = await getToken();
    try {
      await fetch(ip.url + "/uploadPicture/" + jobId + "/" + 0, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchPictures();
    } catch (error) {
      console.error("Error uploading picture:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  // Filter and sort data client-side
  const filteredAreas = useMemo(() => {
    if (!areasByJob.data) return [];
    
    // Create a copy of the data to avoid mutating the original
    let processedData = [...areasByJob.data];
    
    // Apply search filter if search term exists
    if (search.trim()) {
      const searchLower = search.toLowerCase();
      processedData = processedData.filter(area => {
        return (
          area.aname?.toLowerCase().includes(searchLower) ||
          area.description?.toLowerCase().includes(searchLower) ||
          area.name_raspi?.toLowerCase().includes(searchLower)
        );
      });
    }
    
    // Apply sorting
    processedData.sort((a, b) => {
      if (sortBy === 'status') {
        // First sort by status
        if (a.status !== b.status) {
          // For ascending order (In Progress first)
          if (sortOrder === 'asc') {
            // N (In Progress) comes before F (Finished)
            if (a.status === 'N' && b.status === 'F') return -1;
            if (a.status === 'F' && b.status === 'N') return 1;
            if (a.status === 'I' && b.status === 'F') return -1;
            if (a.status === 'F' && b.status === 'I') return 1;
            if (a.status === 'P' && b.status === 'F') return -1;
            if (a.status === 'F' && b.status === 'P') return 1;
            return a.status.localeCompare(b.status);
          } else {
            // For descending order (Finished first)
            if (a.status === 'N' && b.status === 'F') return 1;
            if (a.status === 'F' && b.status === 'N') return -1;
            if (a.status === 'I' && b.status === 'F') return 1;
            if (a.status === 'F' && b.status === 'I') return -1;
            if (a.status === 'P' && b.status === 'F') return 1;
            if (a.status === 'F' && b.status === 'P') return -1;
            return b.status.localeCompare(a.status);
          }
        }
        
        // If status is the same, sort by ID (greater to lesser)
        return b.id - a.id;
      } else if (sortBy === 'id') {
        return sortOrder === 'desc' ? b.id - a.id : a.id - b.id;
      } else if (sortBy === 'aname') {
        return sortOrder === 'asc' 
          ? a.aname?.localeCompare(b.aname || '') 
          : b.aname?.localeCompare(a.aname || '');
      } else if (sortBy === 'robbie_id') {
        return sortOrder === 'asc'
          ? (a.name_raspi || '').localeCompare(b.name_raspi || '')
          : (b.name_raspi || '').localeCompare(a.name_raspi || '');
      }
      
      // Default sort by ID (greater to lesser)
      return b.id - a.id;
    });
    
    return processedData;
  }, [areasByJob.data, search, sortBy, sortOrder]);

  // Calculate totals
  let total_bl = 0;
  let total_dh = 0;

  if (!areasByJob.isPending && areasByJob.data) {
    areasByJob.data.forEach((areajob) => {
      total_bl += areajob.bl;
      total_dh += areajob.dh;
    });
  }

  // Create table columns with the necessary props
  const tableColumns = useMemo(() => columns({ 
    t, 
    contractor_id,
    changeStatusF
  }), [t, changeStatusF]);

  if (areasByJob.isPending) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-2">
        {t("AreasAt")} {areasByJob.data?.[0]?.address.split(",")[0]}
      </div>
      <div className="mb-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={"/jobs/" + contractor_id}>
                {t("Claims")}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>
                {t("Areas")}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <TabsList>
          <TabsTrigger value="areas">{t("Areas")}</TabsTrigger>
          <TabsTrigger value="documents">{t("ClaimDocuments")}</TabsTrigger>
        </TabsList>
        <TabsContent value="areas">
          <DataTable 
            columns={tableColumns} 
            data={filteredAreas}
            search={search}
            setSearch={setSearch}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            showNoResultsMessage={!filteredAreas.length}
            isLoading={areasByJob.isPending}
            totalDh={total_dh}
            totalBl={total_bl}
          />
        </TabsContent>
        <TabsContent value="documents">
          {!loading ? (
            <>
              <div className="claim-pictures">
                {claimPictures.length > 0 ? (
                  claimPictures.map((file) => (
                    <Viewbox
                      key={file.filename}
                      file={file}
                      jobid={jobId}
                      onDelete={handleDelete}
                    />
                  ))
                ) : (
                  <NoResults message={t("NoPictures")} />
                )}
              </div>
              <div className="w-full text-center mt-2">
                <Button onClick={handleClick}>{t("AddClaimDocsPict")}</Button>
              </div>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </>
          ) : (
            <div className="flex-div">
              <Loading />
              <div>
                <h3>{t("LoadingFile")}</h3>
              </div>
            </div>
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
}