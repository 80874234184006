"use client"

import * as React from "react"
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "@/components/ui/table"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

import { useTranslation } from "react-i18next"
import { ArrowUpDown, FilterX } from "lucide-react"

// Debounce function to prevent excessive search updates
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function DataTable({
  columns,
  data,
  search,
  setSearch,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  showNoResultsMessage = false,
  isLoading = false,
  totalDh = 0,
  totalBl = 0,
}) {
  const { t } = useTranslation()
  const [rowSelection, setRowSelection] = React.useState({})
  const [localSearch, setLocalSearch] = React.useState(search);
  const [localPage, setLocalPage] = React.useState(0); // Local page for client-side pagination (0-indexed)
  const pageSize = 10; // Display 10 rows per page
  
  // Use debounced search to prevent excessive updates
  const debouncedSearch = useDebounce(localSearch, 300);
  
  // Update parent search state when debounced search changes
  React.useEffect(() => {
    setSearch(debouncedSearch);
  }, [debouncedSearch, setSearch]);

  // Reset local page when data changes
  React.useEffect(() => {
    setLocalPage(0);
  }, [data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      pagination: {
        pageIndex: localPage,
        pageSize,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newState = updater({
          pageIndex: localPage,
          pageSize,
        });
        setLocalPage(newState.pageIndex);
      } else {
        setLocalPage(updater.pageIndex);
      }
    },
    manualPagination: false, // Use client-side pagination
    manualSorting: true,
    manualFiltering: true,
    pageCount: Math.ceil((data?.length || 0) / pageSize),
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  // Function to reset all filters
  const resetFilters = () => {
    setLocalSearch("");
    setSearch("");
    setLocalPage(0);
    setSortBy("status");
    setSortOrder("asc");
  };

  return (
    <div className="w-full">
      {/* Mobile-friendly filter layout */}
      <div className="flex flex-col space-y-3 py-4 sm:flex-row sm:flex-wrap sm:space-y-0 sm:space-x-2 sm:items-center">
        <Input
          placeholder={t("Search areas...")}
          value={localSearch}
          onChange={(event) => setLocalSearch(event.target.value)}
          className="w-full sm:max-w-sm"
        />
        
        {/* Reset filters button when no results */}
        {showNoResultsMessage && (
          <div className="flex justify-end sm:ml-auto">
            <Button 
              variant="outline" 
              onClick={resetFilters}
              className="w-full sm:w-auto"
            >
              <FilterX className="mr-2 h-4 w-4" />
              {t("Reset Filters")}
            </Button>
          </div>
        )}
      </div>

      {isLoading && (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      )}

      {!isLoading && showNoResultsMessage ? (
        <div className="rounded-md border p-8 text-center">
          <div className="flex flex-col items-center justify-center space-y-4">
            <FilterX className="h-12 w-12 text-muted-foreground" />
            <h3 className="text-lg font-medium">{t("No areas found")}</h3>
            <p className="text-sm text-muted-foreground max-w-md">
              {t("No areas found with the current filters. Try adjusting your filters or reset them to see all areas.")}
            </p>
            <Button 
              variant="outline" 
              onClick={resetFilters}
              className="mt-2"
            >
              {t("Reset All Filters")}
            </Button>
          </div>
        </div>
      ) : !isLoading && (
        <>
          {!isLoading && !showNoResultsMessage && (
            <div className="md:hidden text-sm text-muted-foreground italic mb-4">
              <p className="flex items-center gap-2 border-l-4 border-muted px-3 py-2">
                <span></span> 
                {t("Swipe left in the table to see the status, the area details, and the available actions")}
              </p>
            </div>
          )}
          <div className="rounded-md border overflow-x-auto">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead 
                          key={header.id}
                          className="cursor-pointer"
                          onClick={() => {
                            if (header.column.getCanSort()) {
                              const isAsc = sortOrder === 'asc';
                              setSortOrder(isAsc ? 'desc' : 'asc');
                              setSortBy(header.column.id);
                              setLocalPage(0); // Reset local page when sorting
                            }
                          }}
                        >
                          <div className="flex items-center">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            {header.column.getCanSort() && (
                              <ArrowUpDown className="ml-2 h-4 w-4" />
                            )}
                          </div>
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      {t("NoAreas")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <div className="text-sm font-normal text-muted-foreground">
                      {t("Areas")}: {data?.length || 0}
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <div className="text-sm font-normal text-muted-foreground">
                      {t("Dehumidifiers")}: {totalDh}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-sm font-normal text-muted-foreground">
                      {t("AirMover")}: {totalBl}
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-3 sm:space-y-0 py-4">
            <div className="text-sm text-muted-foreground">
              {t("Showing")} {Math.min(localPage * pageSize + 1, data?.length || 0)} {t("to")} {Math.min((localPage + 1) * pageSize, data?.length || 0)} {t("of")} {data?.length || 0} {t("items")}
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-6">
              <div className="flex items-center space-x-2">
                <p className="text-sm font-medium">{t("Page")}</p>
                <span className="text-sm font-medium">
                  {localPage + 1} {t("of")} {Math.max(1, Math.ceil((data?.length || 0) / pageSize))}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  className="w-full sm:w-auto"
                >
                  {t("Previous")}
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  className="w-full sm:w-auto"
                >
                  {t("Next")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
} 