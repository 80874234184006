"use client"

import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { Loading } from "../UI/Loading";
import { DataTable } from "./table/data-table";
import { columns } from "./table/columns";
import { format, endOfDay, startOfDay, addDays } from "date-fns";


export default function Jobs() {
  const { t } = useTranslation();
  const contractor_Id = localStorage.getItem('temp')
  const isAdmin = contractor_Id === '1' // Check if user is admin
  const [status, setStatus] = useState("N"); // Default to "In Progress"
  const [startDate, setStartDate] = useState(new Date("1 march 2021"));
  const [endDate, setEndDate] = useState(addDays(new Date(), 1)); // Add 1 day to current date to include recent jobs
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedContractor, setSelectedContractor] = useState('');
  
  // Set a very large per_page value to effectively show all rows
  const perPage = 1000;

  // Format dates properly to prevent "Invalid time value" errors
  const formattedStartDate = format(startOfDay(startDate), 'yyyy-MM-dd');
  const formattedEndDate = format(endOfDay(endDate), 'yyyy-MM-dd');

  // Construct query parameters - removed search from API params
  const queryParams = new URLSearchParams({
    page: page.toString(),
    per_page: perPage.toString(),
    date_from: formattedStartDate,
    date_to: formattedEndDate,
    sort_by: sortBy,
    sort_order: sortOrder,
  });

  // Only add contractor_id parameter if not admin or if admin has selected a specific contractor
  if (!isAdmin) {
    queryParams.append("contractor_id", localStorage.getItem('contractor_id') || contractor_Id);
  } else if (isAdmin && selectedContractor && selectedContractor !== 'all') {
    queryParams.append("contractor_id", selectedContractor);
  }

  // Only add status parameter if not "All" (A)
  if (status !== "A") {
    queryParams.append("status", status);
  }

  // Fetch all contractors for admin filter
  const contractors = useAllFetch(
    ['contractors'],
    isAdmin ? '/contractors' : null // Only fetch if admin
  );

  // Updated API endpoint with query parameters
  const jobs = useAllFetch(
    ['jobs', page, perPage, status, formattedStartDate, formattedEndDate, sortBy, sortOrder, isAdmin, selectedContractor], 
    `/jobs?${queryParams.toString()}`
  );
  
  // Fetch tilaclaim data with proper error handling
  const tilaclaim = useAllFetch(
    ['tilaclaim'], 
    localStorage.getItem('contractor_id') ? `/tilaclaim/${localStorage.getItem('contractor_id')}` : null
  );

  // Set filtering state based on API loading state
  useEffect(() => {
    setIsFiltering(jobs.isPending);
  }, [jobs.isPending]);

  // Filter and sort data client-side
  useEffect(() => {
    if (jobs.data) {
      let processedData = [...jobs.data];
      
      // Apply search filter if search term exists
      if (search.trim()) {
        const searchLower = search.toLowerCase();
        processedData = processedData.filter(job => {
          return (
            job.address?.toLowerCase().includes(searchLower) ||
            job.claim_number?.toLowerCase().includes(searchLower) ||
            (job.name && job.name.toLowerCase().includes(searchLower)) ||
            (job.contractor_name && job.contractor_name.toLowerCase().includes(searchLower))
          );
        });
      }
      
      // Custom sort to prioritize "In Progress" claims
      processedData.sort((a, b) => {
        // If sorting by status
        if (sortBy === 'status') {
          // For ascending order (In Progress first)
          if (sortOrder === 'asc') {
            // N (In Progress) comes before F (Finished)
            if (a.status === 'N' && b.status === 'F') return -1;
            if (a.status === 'F' && b.status === 'N') return 1;
            return 0;
          } else {
            // For descending order (Finished first)
            if (a.status === 'N' && b.status === 'F') return 1;
            if (a.status === 'F' && b.status === 'N') return -1;
            return 0;
          }
        }
        
        // For created_at sorting
        if (sortBy === 'created_at') {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          
          return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
        }
        
        // For other sort fields, maintain the server-side sorting
        return 0;
      });
      
      setFilteredData(processedData);
    } else {
      setFilteredData([]);
    }
  }, [jobs.data, search, sortBy, sortOrder]);

  const tableColumns = useMemo(() => columns({ 
    t, 
    temp: localStorage.getItem('temp'),
    timeclaim: tilaclaim || { data: null } // Provide a default value if tilaclaim is undefined
  }), [t, tilaclaim])

  // Fixed operator precedence with parentheses
  if ((jobs.isPending && !filteredData.length) || tilaclaim.isPending) {
    return <Loading />;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 py-6">
      <h1 className="text-2xl font-bold mb-6">{t('Claims')}</h1>
      
      <DataTable 
        columns={tableColumns} 
        data={filteredData || []}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        page={page}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        totalPages={Math.ceil((jobs.data?.total || 0) / perPage)}
        status={status}
        setStatus={setStatus}
        showNoResultsMessage={!jobs.data || jobs.data.length === 0}
        isLoading={isFiltering}
        isAdmin={isAdmin}
        contractors={contractors.data || []}
        selectedContractor={selectedContractor}
        setSelectedContractor={setSelectedContractor}
      />
    </div>
  );
}
