"use client"

import * as React from "react"
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import { useTranslation } from "react-i18next"
import { CalendarIcon, ArrowUpDown, FilterX } from "lucide-react"
import { format, isBefore, isEqual, addDays } from "date-fns"
import { cn } from "@/lib/utils"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

// Debounce function to prevent excessive search updates
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function DataTable({
  columns,
  data,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  page,
  setPage,
  search,
  setSearch,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  totalPages,
  status,
  setStatus,
  showNoResultsMessage = false,
  isLoading = false,
  isAdmin = false,
  contractors = [],
  selectedContractor = '',
  setSelectedContractor = () => {},
}) {
  const { t } = useTranslation()
  const [columnVisibility, setColumnVisibility] = React.useState({
    contractor_name: isAdmin, // Only show contractor column for admin
  })
  const [rowSelection, setRowSelection] = React.useState({})
  const [localSearch, setLocalSearch] = React.useState(search);
  const [localPage, setLocalPage] = React.useState(0); // Local page for client-side pagination (0-indexed)
  const pageSize = 5; // Display 5 rows per page
  
  // Update column visibility when isAdmin changes
  React.useEffect(() => {
    setColumnVisibility(prev => ({
      ...prev,
      contractor_name: isAdmin
    }));
  }, [isAdmin]);
  
  // Use debounced search to prevent excessive updates
  const debouncedSearch = useDebounce(localSearch, 300);
  
  // Update parent search state when debounced search changes
  React.useEffect(() => {
    setSearch(debouncedSearch);
  }, [debouncedSearch, setSearch]);

  // Reset local page when data changes
  React.useEffect(() => {
    setLocalPage(0);
  }, [data]);

  // Ensure contractors is always an array
  const safeContractors = React.useMemo(() => {
    return Array.isArray(contractors) ? contractors : [];
  }, [contractors]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      rowSelection,
      pagination: {
        pageIndex: localPage,
        pageSize,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newState = updater({
          pageIndex: localPage,
          pageSize,
        });
        setLocalPage(newState.pageIndex);
      } else {
        setLocalPage(updater.pageIndex);
      }
    },
    manualPagination: false, // Use client-side pagination
    manualSorting: true,
    manualFiltering: true,
    pageCount: Math.ceil((data?.length || 0) / pageSize),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  // Safe date formatting function
  const formatDate = (date) => {
    try {
      return format(date, "PPP");
    } catch (error) {
      console.error("Date formatting error:", error);
      return "Invalid date";
    }
  };

  // Function to reset all filters
  const resetFilters = () => {
    setStatus("A"); // Set to "All"
    setStartDate(new Date("1 march 2021"));
    setEndDate(addDays(new Date(), 1)); // Update to match the initial state in Jobs component
    setLocalSearch("");
    setSearch("");
    setPage(1);
    setLocalPage(0);
    setSortBy("created_at");
    setSortOrder("desc");
    if (isAdmin) {
      setSelectedContractor("all");
    }
  };

  return (
    <div className="w-full">
      {/* Mobile-friendly filter layout */}
      <div className="flex flex-col space-y-3 py-4 sm:flex-row sm:flex-wrap sm:space-y-0 sm:space-x-2 sm:items-center">
        <Input
          placeholder={t("Search claims...")}
          value={localSearch}
          onChange={(event) => setLocalSearch(event.target.value)}
          className="w-full sm:max-w-sm"
        />
        
        <Select
          value={status}
          onValueChange={(value) => {
            setStatus(value);
            setPage(1); // Reset to first page on status change
            setLocalPage(0); // Reset local page as well
          }}
        >
          <SelectTrigger className="w-full sm:w-[180px]">
            <SelectValue placeholder={t("FilterByStatus")} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="A">{t("All")}</SelectItem>
            <SelectItem value="N">{t("InProgress")}</SelectItem>
            <SelectItem value="F">{t("Finished")}</SelectItem>
          </SelectContent>
        </Select>

        {/* Contractor filter for admin only - using simple Select */}
        {isAdmin && safeContractors.length > 0 && (
          <Select
            value={selectedContractor || "all"}
            onValueChange={(value) => {
              setSelectedContractor(value === "all" ? "" : value);
              setPage(1);
              setLocalPage(0);
            }}
          >
            <SelectTrigger className="w-full sm:w-[250px]">
              <SelectValue placeholder={t("Select contractor...")} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">{t("All Contractors")}</SelectItem>
              {safeContractors.map((contractor) => {
                if (!contractor || !contractor.id || !contractor.name) return null;
                return (
                  <SelectItem key={contractor.id} value={contractor.id.toString()}>
                    {contractor.name}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        )}

        {/* Date pickers in a responsive layout */}
        <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-full sm:w-[180px] justify-start text-left font-normal",
                  !startDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {startDate ? (
                  formatDate(startDate)
                ) : (
                  <span>{t('StartDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={startDate}
                onSelect={(date) => {
                  if (date) {
                    // If selected start date is after end date, update end date too
                    if (endDate && (isBefore(endDate, date) || isEqual(endDate, date))) {
                      setEndDate(date);
                    }
                    setStartDate(date);
                    setPage(1); // Reset to first page on date change
                    setLocalPage(0); // Reset local page as well
                  }
                }}
                initialFocus
                disabled={(date) =>
                  date > new Date() || date < new Date("1 march 2021")
                }
              />
            </PopoverContent>
          </Popover>
          
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-full sm:w-[180px] justify-start text-left font-normal",
                  !endDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {endDate ? (
                  formatDate(endDate)
                ) : (
                  <span>{t('EndDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={endDate}
                onSelect={(date) => {
                  if (date) {
                    setEndDate(date);
                    setPage(1); // Reset to first page on date change
                    setLocalPage(0); // Reset local page as well
                  }
                }}
                initialFocus
                disabled={(date) =>
                  date > new Date() || (startDate && isBefore(date, startDate))
                }
              />
            </PopoverContent>
          </Popover>
        </div>

        {/* Action buttons */}
        <div className="flex justify-end sm:ml-auto">
          {showNoResultsMessage ? (
            <Button 
              variant="outline" 
              onClick={resetFilters}
              className="w-full sm:w-auto"
            >
              <FilterX className="mr-2 h-4 w-4" />
              {t("Reset Filters")}
            </Button>
          ) : (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="w-full sm:w-auto">
                  {t("Columns")}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {table
                  .getAllColumns()
                  .filter(
                    (column) => column.getCanHide()
                  )
                  .map((column) => {
                    // Only show contractor column option for admin
                    if (column.id === "contractor_name" && !isAdmin) {
                      return null;
                    }
                    return (
                      <DropdownMenuCheckboxItem
                        key={column.id}
                        className="capitalize"
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) =>
                          column.toggleVisibility(!!value)
                        }
                      >
                        {column.id}
                      </DropdownMenuCheckboxItem>
                    )
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>

      {isLoading && (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      )}

      {!isLoading && showNoResultsMessage ? (
        <div className="rounded-md border p-8 text-center">
          <div className="flex flex-col items-center justify-center space-y-4">
            <FilterX className="h-12 w-12 text-muted-foreground" />
            <h3 className="text-lg font-medium">{t("No claims found")}</h3>
            <p className="text-sm text-muted-foreground max-w-md">
              {t("No claims found with the current filters. Try adjusting your filters or reset them to see all claims.")}
            </p>
            <Button 
              variant="outline" 
              onClick={resetFilters}
              className="mt-2"
            >
              {t("Reset All Filters")}
            </Button>
          </div>
        </div>
      ) : !isLoading && (
        <>
          {!isLoading && !showNoResultsMessage && (
            <div className="md:hidden text-sm text-muted-foreground italic mb-4">
              <p className="flex items-center gap-2 border-l-4 border-muted px-3 py-2">
                <span></span> 
                {t("Swipe left in the table to see the status, the claim dates, and the available actions")}
              </p>
            </div>
          )}
          <div className="rounded-md border overflow-x-auto">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead 
                          key={header.id}
                          className="cursor-pointer"
                          onClick={() => {
                            if (header.column.getCanSort()) {
                              const isAsc = sortOrder === 'asc';
                              setSortOrder(isAsc ? 'desc' : 'asc');
                              setSortBy(header.column.id);
                              setPage(1); // Reset to first page when sorting
                              setLocalPage(0); // Reset local page as well
                            }
                          }}
                        >
                          <div className="flex items-center">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            {header.column.getCanSort() && (
                              <ArrowUpDown className="ml-2 h-4 w-4" />
                            )}
                          </div>
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      {t("NoClaims")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-3 sm:space-y-0 py-4">
            <div className="text-sm text-muted-foreground">
              {t("Showing")} {Math.min(localPage * pageSize + 1, data?.length || 0)} {t("to")} {Math.min((localPage + 1) * pageSize, data?.length || 0)} {t("of")} {data?.length || 0} {t("items")}
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-6">
              <div className="flex items-center space-x-2">
                <p className="text-sm font-medium">{t("Page")}</p>
                <span className="text-sm font-medium">
                  {localPage + 1} {t("of")} {Math.max(1, Math.ceil((data?.length || 0) / pageSize))}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  className="w-full sm:w-auto"
                >
                  {t("Previous")}
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  className="w-full sm:w-auto"
                >
                  {t("Next")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
} 
