import { useQuery } from "@tanstack/react-query"
import { useAuth } from "@clerk/clerk-react"
import ip from '../../config_ip'

export const useAllFetch = (qkey, endpoint) => {

  const { getToken } = useAuth()

  const getData = async () => {
    // If endpoint is null or undefined, return early to prevent malformed URL
    if (!endpoint) {
      return null;
    }
    
    const token = await getToken()
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    
    // We're not modifying the endpoint anymore since we're handling sampling on the client side
    // This ensures we always get all the data and don't lose any logs
    
    try {
      const resp = await fetch(ip.url + endpoint, options)

      if (resp.ok) {
        const resp2 = await resp.json()
        if (resp2.rows) {
          return resp2.rows // Trae datos de MariaDB
        } else {
          if (resp2.data?.data) {
            if (resp2.data.count > 1) {
              return resp2.data // Trae un arreglo de registros desde postgresql en data
            } else {
              return resp2.data.data // Trae un registro desde postgresql
            }
          } else {
            return resp2.data
          }
        }
      } else {
        console.error(resp.statusText)
      }
    } catch (error) {
      console.error("Error fetch", error)
    }
  }
  
  const query = useQuery({
    queryKey: [qkey], 
    queryFn: getData, 
    retry: 4,
    staleTime: 5 * 60 * 1000, // 5 minutes - keep data fresh for longer to reduce refetches
    cacheTime: 10 * 60 * 1000, // 10 minutes - keep data in cache longer
  })

  return query
}