"use client"

import React from 'react';
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import { useAllFetch } from '../CustomHooks/useAllFetch';
import { Button } from "@/components/ui/button";
import { Loading } from "../UI/Loading";
import { NoResults } from "../UI/NoResults";
import { useTranslation } from "react-i18next";
import { DataTable } from "./table/data-table";
import { columns } from "./table/columns";

const contractor_id = localStorage.getItem('temp')

export default function Contractors() {
	const { t } = useTranslation();
	const listContractors = useAllFetch(['listcontractors'], '/contractors')
	const tableColumns = React.useMemo(() => columns({ t }), [t])

	if (listContractors.isPending) {
		return <Loading />;	}

	return (
		<div className='container'>
			<div className="flex justify-between items-center mb-4">
				<Breadcrumb>
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbLink href={"/jobs/" + contractor_id}>{t('Claims')}</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
						<BreadcrumbItem>
							<BreadcrumbLink href={window.location.href}>{t('List Contractor')}</BreadcrumbLink>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
				<Button onClick={() => window.location.href = "/addcontractor"}>
					{t('NewContractor')}
				</Button>
			</div>

			{!listContractors.data || listContractors.data.length === 0 ? (
				<NoResults message={t('NoContractors')} />
			) : (
				<DataTable 
					columns={tableColumns} 
					data={listContractors.data}
				/>
			)}
		</div>
	)
}
