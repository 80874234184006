import React, { useEffect } from 'react'
import { Button } from '@/components/ui/button.jsx'
import ip from '../../config_ip';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { useAllFetch } from 'components/CustomHooks/useAllFetch';
import { Loading } from "../UI/Loading";
import { useAuth } from "@clerk/clerk-react"

const jobId = window.location.pathname.split('/')[2]

let sub_cos_tot_claim = 0.0
let totenesaving = 0.0
let totcosare = 0.0
let claim = ""
let certificate = ""
let aname = ""
let emailadj = ""
let id_qbo = ""
let insurname = ""
let insuraddress = ""
let totcossaving = 0.0
let chargeallowance = 0.0
let date = new Date()
let day = ""
let month = ""
let year = ""
let nextMonth = ""
let qty1 = 1
let rate1 = 0.0
let amount1 = 0.0
let qty2 = 0.0
let costfixed = 75.0
let amount2 = 0.0
let subtotal = 0.0
let hst = 0.0
let total = 0.0
let savingcost = 0.0
let percenthst = 0.0
let taxCodeRef = 0
let taxName = ""
let totsavCAD = 0.0

const contractor_id = localStorage.getItem('contractor_id')

export default function Invoice() {

  const { getToken } = useAuth()

  const costs = useAllFetch(['costs'], `/getCosts/${jobId}`)
  
  // Store jobId in localStorage when the component loads
  useEffect(() => {
    if (jobId) {
      localStorage.setItem('last_invoice_job_id', jobId);
    }
  }, []);
  
  // Check if we need to redirect (we're on /invoice/ with no jobId but have one stored)
  useEffect(() => {
    if (!jobId && window.location.pathname === '/invoice/') {
      const storedJobId = localStorage.getItem('last_invoice_job_id');
      if (storedJobId) {
        console.log('Redirecting from empty /invoice/ to specific invoice with jobId:', storedJobId);
        window.location.pathname = `/invoice/${storedJobId}`;
      }
    }
  }, []);

  const calc_totals = () => {
    day = date.getUTCDate()
    month = date.getUTCMonth() + 1
    year = date.getUTCFullYear()
    nextMonth = month + 1
    let totcosequ = 0.0
    totenesaving = 0.0
    totcossaving = 0.0
    subtotal = 0.0
    totcosare = 0.0
    savingcost = 0.0
    sub_cos_tot_claim = 0.0
    percenthst = 0.0
    totsavCAD = 0.0
    if (costs.data?.[0].province === 'ON') {
      taxName = "HST (ON)"
      percenthst = costs.data?.[0].taxrate
      taxCodeRef = 6  // Este es el ID en TaxCode en QB
    } else if (costs.data?.[0].province === 'AB') {
      taxName = "GST (AB)"
      percenthst = costs.data?.[0].taxrate
      taxCodeRef = 8
    } else if (costs.data?.[0].province === 'NB') {
      taxName = "HST (NB)"
      percenthst = costs.data?.[0].taxrate
      taxCodeRef = 10
    }
    costs.data?.map(cost => ((
      claim = cost.claimnumber,
      certificate = cost.certificate,
      aname = cost.name,
      emailadj = cost.email_adj,
      id_qbo = cost.id_qbo,
      insurname = cost.insurancename,
      insuraddress = cost.insuranceaddress.split(','),
      totcosequ = totcosequ + cost.cos_tot_dh + cost.cos_tot_bl,
      chargeallowance = totcosequ * 72,
      sub_cos_tot_claim = sub_cos_tot_claim + cost.cos_tot_are,
      totenesaving = totenesaving + (cost.hours_saving < 0 || cost.kwh_saving < 0 ? 0.0 : cost.kwh_saving),
      totcosare = totcosare + (chargeallowance < cost.cos_tot_are ? 0.0 : cost.cos_tot_are),
      totsavCAD = totsavCAD + (cost.hours_saving > 0 ? cost.hours_saving * (cost.cos_tot_bl + cost.cos_tot_dh) : 0.0) // calcula el costo total de ahorro en cad
    )))
    totcossaving = (totenesaving * 0.17).toFixed(2)
    chargeallowance = totcosequ * 72
    // Calculo costo por ahorro de energia
    // console.log(chargeallowance, sub_cos_tot_claim)
    if (totsavCAD > 0) {
      qty1 = 1
      rate1 = (((totsavCAD)*1.15)*0.3).toFixed(2)
      amount1 = (rate1 * qty1).toFixed(2)
      savingcost = (totsavCAD*1.15).toFixed(2)
    } else {
      amount1 = 0
    }
    // Calculo Fee por robbie
    qty2 = parseFloat(costs.data?.length)
    amount2 = (costfixed * qty2).toFixed(2)
    // Suma de las cantidades para dar un subtotal
    if (amount1 < 0) {
      subtotal = parseFloat(amount2).toFixed(2)
    } else {
      subtotal = parseFloat(amount1) + parseFloat(amount2)
    }
    hst = (subtotal * parseFloat(percenthst/100)).toFixed(2)
    total = (parseFloat(subtotal) + parseFloat(hst)).toFixed(2)
  }

  calc_totals()
  
  const handledValidated = async () => {
    const tokenu = await getToken()
    // Store the jobId before redirecting to QBO
    if (jobId) {
      console.log('Storing jobId before QBO redirect:', jobId);
      localStorage.setItem('last_invoice_job_id', jobId);
    }
    let authUri = await fetch(ip.url + '/authUri/' + jobId, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json',
        'Authorization': `bearer ${tokenu}`
      }
    })
    authUri = await authUri.json()
    console.log('QBO auth URI:', authUri)
    window.location.href = authUri
  }

  const handledCreate = async () => {
    let items
    if (amount1 > 0) {
      items = [
        [
          {
            "Description": "Drying Equipment Efficiency Charges on Claim\n#" + claim + ".\n\nBy partnering with Robbie on this claim, you saved a\ntotal of $"+ savingcost + " in drying services.\n\nTogether we saved the planet $" + totcossaving + " on energy waste\n(" + totenesaving.toFixed(2) + "Kwh)",
            "Amount": amount1,
            "unitPrice": rate1,
            "Qty": (qty1).toString(),
            "TaxCodeRef": taxCodeRef    // "6" 'ON', "8" 'AB'
          },
          {
            "Description": "IOT Monitoring and Control Station Robbie Set Up Fee\n$75.00 per unit. on Claim #" + claim,
            "Amount": amount2,
            "unitPrice": (costfixed).toString(),
            "Qty": (qty2).toString(),
            "TaxCodeRef": taxCodeRef    // "6" 'ON', "8" 'AB'
          }
        ],
        [
          {
            "Amount": subtotal,
            "totalTax": hst,
            "PercentBased": true,
            "taxPercent": percenthst,
            "netAmountTaxable": subtotal,
            "customerMemo":
              "The content of this document refers to the report detailed in the Robbie Monitoring Certificate #" + certificate + " for claim number# " + claim + ". If you have any questions, please do not hesitate to contact the toll-free number 1-866-267-7250, option 4.",
            "CustomerRefvalue": id_qbo,
            "BillEmailAddress": emailadj,
          }
        ]
      ]
    } else {
      items = [
        [
          {
            "Description": "IOT Monitoring and Control Station Robbie Rental fee\n$75.00 per unit on Claim #" + claim,
            "Amount": amount2,
            "unitPrice": (costfixed).toString(),
            "Qty": (qty2).toString(),
            "TaxCodeRef": taxCodeRef,
          }
        ],
        [
          {
            "Amount": subtotal,
            "totalTax": hst,
            "PercentBased": true,
            "taxPercent": percenthst,
            "netAmountTaxable": subtotal,
            "customerMemo":
              "The content of this document refers to the report detailed in the Robbie Monitoring Certificate #" + certificate + " for claim number # " + claim + ". If you have any questions, please do not hesitate to contact the toll-free number 1-866-267-7250, option 4.",
            "CustomerRefvalue": id_qbo,
            "BillEmailAddress": emailadj,
          }
        ]
      ]
    }

    const tokenu = await getToken()

    let response = await fetch(ip.url + "/createInvoice", {
      method: "POST",
      body: JSON.stringify(items),
		 	headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
        "Authorization": `bearer ${tokenu}`
			},
    })
    response = await response.json()

    if (response.Invoice.DocNumber !== "0") {
      let resp = await fetch(ip.url + "/updateClaim", {
        method: "post",
        body: JSON.stringify({docnumber: response.Invoice.DocNumber, txndate: response.Invoice.TxnDate, duedate: response.Invoice.DueDate, jobid: jobId}),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `bearer ${tokenu}`
        }
      })
      resp = await resp.json()
      if (resp.update === 'ok') {
        window.location.pathname = "/lcosts/" + jobId
      }
    }
  }

  if (costs.isPending) {
    return <Loading />;  }

  return (
    <div className='container'>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={"/lcosts/" + jobId}>Costs</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Invoice</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="m-5">
        <div style={{"textAlign": "left" }}>
          <h2 style={{"marginTop": "40px", "marginBottom": "15px"}}>INVOICE DRAFT</h2>
        </div>
        <div style={{"display": "flex", "flexDirection": "row"}}>
          <div style={{"textAlign": "left", "width": "45%" }}>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>BILL TO</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>{insurname} {id_qbo}</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>{insuraddress[0]}</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>{insuraddress[1] + "," + insuraddress[2] + "," + insuraddress[3]}</p>
          </div>
          <div style={{"textAlign": "left", "width": "10%" }}>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>ADJUSTER</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>E-MAIL</p>
          </div>
          <div style={{"textAlign": "left", "width": "20%" }}>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>{aname}</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>{emailadj}</p>
          </div>
          <div style={{"textAlign": "left", "width": "15%" }}>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>INVOICE</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>DATE</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>TERMS</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>DUE DATE</p>
          </div>
          <div style={{"textAlign": "left", "width": "15%" }}>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>Pending ...</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>{day}/{month}/{year}</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>Net 30</p>
            <p style={{"marginTop": "1px", "marginBottom": "1px", }}>{day}/{nextMonth}/{year}</p>
          </div>
        </div>
        <div style={{"display": "flex", "flexDirection": "row", "background": "lightgray", "marginTop": "20px", "paddingBottom": "4px", "paddingTop": "4px", "fontSize": 20}}>
          <div style={{"textAlign": "left", "width": "18%"}}></div>
          <div style={{"textAlign": "left", "width": "42%"}}>DESCRIPTION</div>
          <div style={{"textAlign": "right", "width": "10%"}}>TAX</div>
          <div style={{"textAlign": "right", "width": "8%"}}>QTY</div>
          <div style={{"textAlign": "right", "width": "9%"}}>RATE</div>
          <div style={{"textAlign": "right", "width": "12%"}}>AMOUNT</div>
        </div>
        {amount1 <= 0 ? null :
        <div style={{"display": "flex", "flexDirection": "row", "marginTop": "20px", "paddingBottom": "4px", "paddingTop": "4px", "fontSize": 20, "fontWeight": "bold" }}>
          <div style={{"textAlign": "left", "width": "18%"}}>Sales</div>
          <div style={{"textAlign": "left", "width": "42%"}}>
            <p>Drying Equipment Efficiency Charges on Claim #{claim}.</p>
            <p>By partnering with Robbie on this claim, you saved a total of ${savingcost} in drying services.</p>
            <p>Together we saved the planet ${totcossaving} on energy waste ({totenesaving.toFixed(2)}) Kwh</p>
          </div>
          <div style={{"textAlign": "right", "width": "10%"}}>{taxName}</div>
          <div style={{"textAlign": "right", "width": "8%"}}>{qty1}</div>
          <div style={{"textAlign": "right", "width": "9%"}}>{rate1}</div>
          <div style={{"textAlign": "right", "width": "12%"}}>{amount1}</div>
        </div>
        }
        <div style={{"display": "flex", "flexDirection": "row", "paddingBottom": "4px", "paddingTop": "4px", "fontSize": 20, "fontWeight": "bold"}}>
          <div style={{"textAlign": "left", "width": "18%"}}>Sales</div>
          <div style={{"textAlign": "left", "width": "42%"}}>
            <p>IOT Monitoring and Control Station Robbie Rental fee ${costfixed.toFixed(2)} per unit on Claim # {claim}</p>
          </div>
          <div style={{"textAlign": "right", "width": "10%"}}>{taxName}</div>
          <div style={{"textAlign": "right", "width": "8%"}}>{qty2}</div>
          <div style={{"textAlign": "right", "width": "9%"}}>{costfixed.toFixed(2)}</div>
          <div style={{"textAlign": "right", "width": "12%"}}>{amount2}</div>
        </div>
        <div><hr /></div>
        <div style={{"display": "flex", "flexDirection": "row"}}>
          <div style={{"width": "50%", "textAlign": "left", "paddingRight": "3px"}}>
            <p>The content of this document refers to the report detailed in the Robbie Monitoring Certificate #{certificate}. If you have any questions, please do not hesitate to contact the toll-free number 1-866-267-7250, option 4.</p>
          </div>
          <div style={{"width": "25%", "textAlign": "left", "fontSize": 20}}>
            <p>SUBTOTAL</p>
            <p>{taxName} @ {percenthst}%</p>
            <p>TOTAL</p>
          </div>
          <div style={{"width": "25%", "textAlign": "right", "fontSize": 20}}>
            <p>{subtotal.toFixed(2).toString()}</p>
            <p>{hst}</p>
            <p>{total.toString()}</p>
          </div>
        </div>
        <div style={{"display": "flex", "flexDirection": "row"}}>
          <div style={{"width": "50%", "textAlign": "right", "fontSize": 20}}>
            
          </div>
          <div style={{"width": "50%", "textAlign": "left", "fontSize": 20}}>
            <div><hr /></div>
            <div style={{"display": "flex", "flexDirection": "row", "width": "100%"}}>
              <div style={{"width": "50%"}}>
                <p>BALANCE DUE</p>
              </div>
              <div style={{"width": "50%", "textAlign": "right", "fontSize": 23, "fontWeight": "bold"}}>
                <p>{total.toString()}</p>
              </div>
            </div>
          </div>
        </div>
        <p style={{"fontSize": 20, "fontWeight": "bold", "textAlign": "left", "marginBottom": "0px"}}>TAX SUMMARY</p>
        <div style={{"display": "flex", "flexDirection": "row", "background": "lightgray", "paddingBottom": "4px", "paddingTop": "4px", "fontSize": 20, "fontWeight": "bold"}}>
          <div style={{"textAlign": "right", "width": "40%"}}>RATE</div>
          <div style={{"textAlign": "right", "width": "30%"}}>TAX</div>
          <div style={{"textAlign": "right", "width": "29%"}}>NET</div>
        </div>
        <div style={{"display": "flex", "flexDirection": "row", "marginTop": "5px", "paddingBottom": "4px", "paddingTop": "4px", "fontSize": 20, "fontWeight": "bold"}}>
          <div style={{"textAlign": "right", "width": "40%"}}>{taxName} @ {percenthst}%</div>
          <div style={{"textAlign": "right", "width": "30%"}}>{hst}</div>
          <div style={{"textAlign": "right", "width": "29%"}}>{subtotal.toFixed(2).toString()}</div>
        </div>
        <div>
          <Button onClick={handledValidated} className="mt-5 mr-2">Validate on QBO</Button>
          <Button onClick={handledCreate} className="mt-5 mr-2">Create on QBO</Button>
        </div>
      </div>
    </div>
  )
}

//import qbo_default from './C2QB_white_btn_lg_default.png'
//import qbo_hover from './C2QB_white_btn_lg_hover.png'
    /* <Button onClick={handledModify} className="mt-5 mr-2">Modify</Button>
    <Button onClick={handledVoid} className="mt-5 mr-2">Void</Button>
    <Button onClick={handledSelect} className="mt-5 mr-2">Select</Button>
    <Button onClick={handledRead} className="mt-5 mr-2">Read</Button>
    <Button onClick={handledDelete} className="mt-5 mr-2">Delete</Button>
    <Button onClick={handledView} className="mt-5">View PDF</Button>
    <div style={{"textAlign": "left" }}>
      <h4>Robbie Restoration Technologies Invoice</h4>
      <p style={{"marginTop": "1px", "marginBottom": "1px", }}>20 - 1040 Martin Grove Rd</p>
      <p style={{"marginTop": "1px", "marginBottom": "1px", }}>Toronto ON M9W 4W4</p>
      <p style={{"marginTop": "1px", "marginBottom": "1px", }}>info@robbiert.com</p>
      <p style={{"marginTop": "1px", "marginBottom": "1px", }}>https://www.robbiert.com</p>
      <p style={{"marginTop": "1px", "marginBottom": "1px", }}>GST/HST Registration No.: 771247145RT0001</p>
    </div> */

  // const launchPopup = (path) => {
  //   var win;
  //   var checkConnect;
  //   var parameters = "location=1,width=800,height=650";
  //   parameters += ",left=" + (Screen.width - 800) / 2 + ",top=" + (Screen.height - 650) / 2;
  //   // Launch Popup
  //   win = window.open(path, 'connectPopup', parameters);
  //   fetch(ip.url + path, {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*"
  //     }
  //   })
  // }
  /* <Button
    style={{"padding": "0px"}}
    type="submit"
    onClick={() => launchPopup('/connect_to_quickbooks')}
  >
    <img
      style={{"height": "40px",}}
      src={qbo_default}
      onMouseOver={() => qbo_hover}
      alt=""
    />
  </Button> */
