import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, number } from "yup";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import ip from "../../config_ip";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const contractor_id = localStorage.getItem('contractor_id')
const nameuser = localStorage.getItem('name')
let tokenu = ''

export default function JobForm() {
  const { t } = useTranslation()
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { getToken } = useAuth()

  useEffect(() => {
    if (dialogOpen) {
      const timer = setTimeout(() => {
        setDialogOpen(false)
        window.location.href = "/jobs/" + contractor_id
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [dialogOpen])

  const assigninsucont = useAllFetch(['assigninsucont'], '/assigninsucont/' + contractor_id)

  const handleAddressChange = async (value) => {
    setAddress(value);
    if (!value) {
      setSuggestions([]);
      return;
    }
    
    try {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      const results = await autocompleteService.getPlacePredictions({
        input: value,
        componentRestrictions: { country: ["ca", "us"] }
      });
      setSuggestions(results.predictions || []);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };

  const handleSelectAddress = async (selectedAddress) => {
    setAddress(selectedAddress);
    const results = await geocodeByAddress(selectedAddress);
    const { lat, lng } = await getLatLng(results[0]);
    form.setValue('address', selectedAddress);
    form.setValue('postalcode', results[0].address_components.pop().long_name.replace(' ', ''));
    form.setValue('province', results[0].address_components.slice(-2, -1)[0].short_name);
    form.setValue('lat', lat);
    form.setValue('lng', lng);
    setOpen(false);
  };

  const mutation = useMutation({
    mutationFn: async (data) => {
      tokenu = await getToken()
      const response = await fetch(ip.url + '/addJob', {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
    }
  })

  const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: async function (json) {
          await fetch(ip.url + "/send-email/" + json.insertId + "/0/" + data.address + "/null/C/null/" + navigator.language.split('-',1)[0],
            {
              method: "get",
              headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": `Bearer ${tokenu}`
              }
            }
          )
          setDialogOpen(true)
        },
        onError: function () {
          console.log('Request Error')
        }
      }
    )
  }

  const jobSchema = object().shape({
    address: string().required(),
    postalcode: string().required(),
    description: string()
      .min(10, "Too Long!")
      .max(50, "Too Long!"),
    insuranceid: number().required(),
    causeloss: string()
      .min(4, "Too Short!")
      .max(100, "Too Long!"),
  })

  const form = useForm({
    resolver: yupResolver(jobSchema),
    defaultValues: {
      description: '',
      address: '',
      postalcode: '',
      claimnumber: 0,
      insuranceid: '',
      contractor_id: contractor_id,
      lat: '',
      lng: '',
      adjuster_id: 10,
      user: nameuser,
      causeloss: '',
      province: '',
    }
  })

  if (assigninsucont.isLoading) {
    return <p>Loading</p>
  }

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>{t('Claims')}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>{t('CreateClaim')}</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="mt-6 md:mt-8">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('Address')}</FormLabel>
                  <FormControl>
                    <Popover open={open} onOpenChange={setOpen}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={open}
                          className="w-full justify-between"
                        >
                          {field.value || t('Search Address ...')}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="p-0" style={{ width: 'var(--radix-popover-trigger-width)' }}>
                        <Command shouldFilter={false}>
                          <CommandInput 
                            placeholder={t('Search Address ...')}
                            value={address}
                            onValueChange={handleAddressChange}
                            className="border-none"
                          />
                          <CommandList>
                            <CommandEmpty>{t('No addresses found.')}</CommandEmpty>
                            <CommandGroup>
                              {suggestions.map((suggestion) => (
                                <CommandItem
                                  key={suggestion.place_id}
                                  onSelect={() => handleSelectAddress(suggestion.description)}
                                  className="cursor-pointer"
                                >
                                  {suggestion.description}
                                  <Check
                                    className={cn(
                                      "ml-auto",
                                      address === suggestion.description ? "opacity-100" : "opacity-0"
                                    )}
                                  />
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="postalcode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('PostalCode')}</FormLabel>
                  <FormControl>
                    <Input
                      className="form-input"
                      placeholder={t('Select Address First')}
                      {...field}
                      readOnly
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('BuildingDescription')}</FormLabel>
                  <FormControl>
                    <Input
                      className="form-input"
                      placeholder={t('Enter Building Description')}
                      {...field}
                      {...form.register("description")}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="causeloss"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('CauseLoss')}</FormLabel>
                  <FormControl>
                    <Input
                      className="form-input"
                      placeholder={t('Enter Cause Of Loss')}
                      {...field}
                      {...form.register("causeloss")}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="insuranceid"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('InsuranceCompany')}</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      className="select"
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={t('Select Insurance Company')} />
                      </SelectTrigger>
                      <SelectContent>
                        {assigninsucont.data?.map((insurance) => (
                          <SelectItem
                            key={insurance.id}
                            value={insurance.id.toString()}
                          >
                            {insurance.insurancename}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
              <DialogTrigger asChild>
                <Button 
                  type="submit" 
                  className="w-full sm:w-auto"
                  disabled={form.formState.isSubmitSuccessful}
                >
                  {t('Create Claim')}
                </Button>
              </DialogTrigger>
              {mutation.isSuccess && (
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>{t('Claim Created')}</DialogTitle>
                  </DialogHeader>
                  <DialogFooter>
                    <div className="w-full text-center text-sm text-muted-foreground">
                      {t('Redirecting')}
                    </div>
                  </DialogFooter>
                </DialogContent>
              )}
            </Dialog>
          </form>
        </Form>
      </div>
    </div>
  );
}
