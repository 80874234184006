import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from "@/components/ui/select.jsx";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from "@/components/ui/breadcrumb";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { useAllFetch } from "components/CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useMutation } from "@tanstack/react-query";
import { Loading } from "../UI/Loading";
import ip from "../../config_ip";
import { useTranslation } from "react-i18next";

const EditJob = ({ ronly }) => {
	const { t } = useTranslation()
	const [address, setAddress] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const [open, setOpen] = useState(false);

	const { getToken } = useAuth();
	const jobId = window.location.pathname.split('/')[2];
	const contractorId = localStorage.getItem('contractor_id');

	const { data: jobData, isPending: jobLoading } = useAllFetch(['job'], `/getjob/${jobId}`);
	const { data: insurances, isPending: insurancesLoading } = useAllFetch(['insurances'], '/insurances');

	const form = useForm({
		resolver: yupResolver(object().shape({
			description: string().required(),
			causeloss: string().max(500, "Too Long!").required(),
			address: string().required(),
			postalcode: string().required(),
			insuranceid: number().required(),
			claimnumber: ronly && jobData?.insuranceid === 7 ? string().matches(/^\d{4}[a-zA-Z]\d{4}$|^\d{10}$/) : null,
			adjuster_id: ronly ? number().moreThan(0) : number(),
		})),
		defaultValues: {},
	});

	useEffect(() => {
		if (jobData) {
			form.reset({
				address: jobData?.address || "",
				description: jobData?.description || "",
				postalcode: jobData?.postalcode || "",
				claimnumber: jobData?.claimnumber || "",
				insuranceid: jobData?.insuranceid?.toString() || "",
				contractor_id: jobData?.contractor_id?.toString() || "",
				lat: jobData?.lat || "",
				lng: jobData?.lng || "",
				adjuster_id: jobData?.adjuster_id?.toString() || "",
				user: jobData?.user || "",
				causeloss: jobData?.causeloss || "",
				province: jobData?.province || "",
				id: jobData?.id?.toString() || "",
			});
			setAddress(jobData?.address || "");
		}
	}, [jobData, form]);

	const selectedInsuranceId = form.watch('insuranceid');

	const { data: adjustersData, isPending: adjustersLoading } = useAllFetch(
		selectedInsuranceId ? ['adjusters', selectedInsuranceId] : null,
		selectedInsuranceId ? `/adjusters/${selectedInsuranceId}` : '',
		{
		  enabled: !!selectedInsuranceId,
		}
	);
	
	const adjusters = adjustersData || [];

	const handleSelectAddress = async (selectedAddress) => {
		setAddress(selectedAddress);
		const results = await geocodeByAddress(selectedAddress);
		const { lat, lng } = await getLatLng(results[0]);
		form.setValue('address', selectedAddress);
		form.setValue('postalcode', results[0].address_components.pop().long_name.replace(' ', ''));
		form.setValue('province', results[0].address_components.slice(-2, -1)[0].short_name);
		form.setValue('lat', lat);
		form.setValue('lng', lng);
		setOpen(false);
	};

	const handleAddressChange = async (value) => {
		setAddress(value);
		if (!value) {
			setSuggestions([]);
			return;
		}
		
		try {
			const autocompleteService = new window.google.maps.places.AutocompleteService();
			const results = await autocompleteService.getPlacePredictions({
				input: value,
				componentRestrictions: { country: ["ca", "us"] }
			});
			setSuggestions(results.predictions || []);
		} catch (error) {
			console.error("Error fetching suggestions:", error);
			setSuggestions([]);
		}
	};

	const mutation = useMutation({
		mutationFn: async (data) => {
			const token = await getToken();
			const response = await fetch(`${ip.url}/updateJob/${jobId}`, {
				method: "PUT",
				body: JSON.stringify(data),
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
			});
			return response.json();
		},
		onSuccess: async () => {
			if (ronly) {
				const token = await getToken();
				const addressShort = form.getValues("address").split(',')[0].replace('#', '');
				await fetch(ip.url + '/send-email/' + jobId + '/0/' + addressShort + '/' + adjusters[0].username + '/R/null/' + navigator.language.split('-',1)[0], {
					method: "GET",
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/json",
						"Authorization": `Bearer ${token}`,
					},
				});
				window.location.pathname = '/certificate2/' + jobId
			} else {
				window.location.pathname = '/jobs/' + contractorId;
			}
		},
	});

	const onSubmit = (data) => {
		mutation.mutate(data);
	};

	if (jobLoading || insurancesLoading || adjustersLoading) {
		return <Loading />;
	}

	return (
		<div className="container">
			<div className="mb-3">
				<Breadcrumb>
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbLink href={`/jobs/${contractorId}`}>{t('Claims')}</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
						<BreadcrumbItem>
							<BreadcrumbLink href={window.location.href}>{t('EditClaim')}</BreadcrumbLink>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
			</div>
			<div className="mt-6 md:mt-8">
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
						<FormField
							control={form.control}
							name="address"
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t('Address')}</FormLabel>
									<FormControl>
										<Popover open={open} onOpenChange={setOpen}>
											<PopoverTrigger asChild>
												<Button
													variant="outline"
													role="combobox"
													aria-expanded={open}
													className="w-full justify-between"
													disabled={ronly}
												>
													{field.value || t('Search Address ...')}
													<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
												</Button>
											</PopoverTrigger>
											<PopoverContent className="p-0" style={{ width: 'var(--radix-popover-trigger-width)' }}>
												<Command shouldFilter={false}>
													<CommandInput 
														placeholder={t('Search Address ...')}
														value={address}
														onValueChange={handleAddressChange}
														className="border-none"
													/>
													<CommandList>
														<CommandEmpty>{t('No addresses found.')}</CommandEmpty>
														<CommandGroup>
															{suggestions.map((suggestion) => (
																<CommandItem
																	key={suggestion.place_id}
																	onSelect={() => handleSelectAddress(suggestion.description)}
																	className="cursor-pointer"
																>
																	{suggestion.description}
																	<Check
																		className={cn(
																			"ml-auto",
																			address === suggestion.description ? "opacity-100" : "opacity-0"
																		)}
																	/>
																</CommandItem>
															))}
														</CommandGroup>
													</CommandList>
												</Command>
											</PopoverContent>
										</Popover>
									</FormControl>
								</FormItem>
							)}
						/>
						<FormField control={form.control} name="description" render={({ field }) => (
							<FormItem>
								<FormLabel>{t('BuildingDescription')}</FormLabel>
								<FormControl>
									<Input {...field} readOnly={ronly} />
								</FormControl>
							</FormItem>
						)} />
						<FormField control={form.control} name="causeloss" render={({ field }) => (
							<FormItem>
								<FormLabel>{t('CauseLoss')}</FormLabel>
								<FormControl>
									<Input {...field} readOnly={ronly} />
								</FormControl>
							</FormItem>
						)} />
						<FormField control={form.control} name="postalcode" render={({ field }) => (
							<FormItem>
								<FormLabel>{t('PostalCode')}</FormLabel>
								<FormControl>
									<Input {...field} readOnly={ronly} />
								</FormControl>
							</FormItem>
						)} />
						<FormField control={form.control} name="insuranceid" render={({ field }) => (
							<FormItem>
								<FormLabel>{t('Insurances')}</FormLabel>
								<FormControl>
									<Select onValueChange={(value) => form.setValue('insuranceid', value)} value={field.value}>
										<SelectTrigger>
											<SelectValue placeholder={t('Select Insurance name')} />
										</SelectTrigger>
										<SelectContent>
											{insurances?.map((insurance) => (
												<SelectItem key={insurance.id} value={insurance.id.toString()}>
													{insurance.insurancename}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</FormControl>
							</FormItem>
						)} />
						{ronly && (
							<FormField control={form.control} name="claimnumber" render={({ field }) => (
								<FormItem>
									<FormLabel>{t('ClaimNumber')}</FormLabel>
									<FormControl>
										<Input {...field} placeholder={selectedInsuranceId === 7 ? "Enter Claim Number NNNNXNNNN or NNNNNNNNNN" : "Enter Claim Number"} />
									</FormControl>
								</FormItem>
							)} />
						)}
						{ronly && (
							<FormField control={form.control} name="adjuster_id" render={({ field }) => (
								<FormItem>
									<FormLabel>{t('Adjusters')}</FormLabel>
									<FormControl>
										<Select onValueChange={field.onChange} value={field.value}>
											<SelectTrigger>
												<SelectValue placeholder={t('Select Adjuster name')} />
											</SelectTrigger>
											<SelectContent>
												{adjusters.length > 0 ? (
													adjusters
													.filter(adjuster => adjuster.active)
													.map((adjuster) => (
														<SelectItem key={adjuster.id} value={adjuster.id.toString()}>
															{adjuster.aname}
														</SelectItem>
													))
												) : (
													<SelectItem key="no-adjusters" value="no-adjusters" disabled>
														{t('NoAdjusters')}
													</SelectItem>
												)}
											</SelectContent>
										</Select>
									</FormControl>
								</FormItem>
							)} />
						)}
						<Button type="submit" className="w-full sm:w-auto">{t('Save')}</Button>
					</form>
				</Form>
			</div>
		</div>
	);
};

export default EditJob;