import React, { useState, useEffect } from "react";
import ip from "../../config_ip";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import "./styles.scss";
import Allfetch from "../Allfetch";

const jobId = window.location.pathname.split('/')[2]    // para[2] trae el link de authorizacion

export default function Authorization () {

  const [verify, setVerify] = useState(0)

  useEffect(() => {
    const getAuthVerify = async () => {
      Allfetch('/autverify/' + jobId, setVerify)
    }
    getAuthVerify()
  }, [])

  useEffect(() => {
    if (verify[0]?.authoc === '1') {
      window.location.href = '/linktemp/' + jobId
    }
  }, [verify])

  const updateCustom = async (emailcustom, conditions, idjob) => {
    const data = {
      emailcustom: emailcustom,
      conditions: conditions,
      idjob: idjob,
    }
    var resp = await fetch(ip.url + "/upCustom", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    resp = await resp.json()
    if (resp.data?.emailc !== null || resp.data?.emailc !== '') {
      window.location.href = '/linktemp/' + jobId
    }
  }

  return (
    <div className="container">
      <Formik
        initialValues={{
          emailcustom: "",
          conditions: false,
        }}
        validationSchema={Yup.object().shape({
          emailcustom: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          conditions: Yup.bool()
            .oneOf([true], 'You must accept to proceed')
            .required('You must accept to proceed'),
        })}
        onSubmit={(values) => {
          setTimeout(() => {
            updateCustom(
              values.emailcustom,
              values.conditions,
              values.idjob = jobId,
            );
          }, 500);
        }}
      >
      {(props) => {
        const { values, touched, errors, isSubmitting, handleSubmit, handleChange } = props
        return (
          <form id="customer" autoComplete="off" onSubmit={handleSubmit} className="space-y-6 max-w-xl mx-auto">
            <div className="min-h-[400px]">
              <div className="space-y-6">
                <div className="title">
                  <p className="text-xl font-semibold">One last step</p>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="emailcustom">Please provide us with your E-mail address.</Label>
                  <p className="text-sm text-muted-foreground">
                    We will never share your email with anyone else.
                  </p>
                  <Input
                    id="emailcustom"
                    name="emailcustom"
                    type="email"
                    placeholder="E-mail"
                    value={values.emailcustom}
                    onChange={handleChange}
                  />
                  {errors.emailcustom && touched.emailcustom && (
                    <p className="text-sm text-destructive">
                      {errors.emailcustom}
                    </p>
                  )}
                </div>
                <div className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="conditions"
                      name="conditions"
                      checked={values.conditions}
                      onCheckedChange={(checked) => {
                        handleChange({
                          target: {
                            name: 'conditions',
                            value: checked
                          }
                        });
                      }}
                    />
                    <Label 
                      htmlFor="conditions" 
                      className="text-sm font-medium leading-none mt-0 mb-0 cursor-pointer"
                    >
                      Allow Robbie Restoration Technologies Inc. to send me emails
                    </Label>
                  </div>
                  {errors.conditions && touched.conditions && (
                    <p className="text-sm text-destructive pl-6">
                      {errors.conditions}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <Button type="submit" disabled={isSubmitting} className="w-full sm:w-auto">
                Send
              </Button>
            </div>
          </form>
        )}}
      </Formik>
    </div>
  )
}
//, 'Accepting is required to proceed'