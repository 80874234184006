import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { object, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaSave, FaReply } from "react-icons/fa";
import config_ip from '../../../config_ip';
import { useAllFetch } from '../../CustomHooks/useAllFetch';
import { useAuth } from "@clerk/clerk-react";
import { Loading } from "../../UI/Loading";
import { useTranslation } from "react-i18next";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { MoreHorizontal, Check, ChevronsUpDown, ArrowUpDown, ArrowUp, ArrowDown } from "lucide-react";
import { cn } from "@/lib/utils";

const contractor_id = window.location.pathname.split('/')[2];
const contractorName = window.location.pathname.split('/')[3];

export default function Assigninsu() {
  const { t } = useTranslation();
  const [insurco_id, setInsurco_id] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [insuranceSearch, setInsuranceSearch] = useState("");
  const [openInsurance, setOpenInsurance] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const { getToken } = useAuth();

  const insucont = useAllFetch(['insucont'], '/insucont/' + contractor_id);
  const assigninsu = useAllFetch(['assigninsu'], '/assigninsucont/' + contractor_id);

  const onSubmit = async (data) => {
    const tokenu = await getToken();
    await fetch(config_ip.url + "/assigninsu", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenu}`
      },
    });
    window.location.href = `/assigninsu/${contractor_id}/${contractorName}`;
  };

  const desassign = async (insurco_id, contractor_id) => {
    const tokenu = await getToken();
    await fetch(config_ip.url + '/desassigninsu/' + insurco_id + '/' + contractor_id, {
      method: "get",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenu}`
      },
    });
    window.location.href = `/assigninsu/${contractor_id}/${contractorName}`;
  };

  const assigninsuSchema = object().shape({
    insurco_id: number()
  });

  const form = useForm({
    resolver: yupResolver(assigninsuSchema),
    values: {
      insurco_id,
      contractor_id,
    }
  });

  const onSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedInsurances = (insurances) => {
    if (!sortConfig.key) return insurances;

    return [...insurances].sort((a, b) => {
      if (sortConfig.key === 'insurancename') {
        return sortConfig.direction === 'asc' 
          ? a.insurancename.localeCompare(b.insurancename)
          : b.insurancename.localeCompare(a.insurancename);
      }
      return 0;
    });
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown className="ml-2 h-4 w-4" />;
    }
    return sortConfig.direction === 'asc' 
      ? <ArrowUp className="ml-2 h-4 w-4" />
      : <ArrowDown className="ml-2 h-4 w-4" />;
  };

  if (insucont.isPending || assigninsu.isPending) {
    return <Loading />;
  }

  const filteredInsurances = assigninsu.data?.filter(insurance => 
    insurance.insurancename.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedInsurances = getSortedInsurances(filteredInsurances || []);

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-4">{decodeURIComponent(contractorName).replaceAll("%20", " ")}</div>
      <div className="mb-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={"/jobs/" + contractor_id}>{t('Claims')}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/lcontractors">{t('List Contractor')}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>{t('Assign Insurances')}</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="mt-6 md:mt-8">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="insurco_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('Assign Insurance')}</FormLabel>
                  <FormControl>
                    <Popover open={openInsurance} onOpenChange={setOpenInsurance}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={openInsurance}
                          className="w-full justify-between"
                        >
                          {field.value ? 
                            insucont.data?.find(ins => ins.id.toString() === field.value)?.insurancename 
                            : t('Select Insurance')}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="p-0" style={{ width: 'var(--radix-popover-trigger-width)' }}>
                        <Command shouldFilter={false}>
                          <CommandInput 
                            placeholder={t('Search Insurance...')}
                            value={insuranceSearch}
                            onValueChange={setInsuranceSearch}
                            className="border-none"
                          />
                          <CommandList>
                            <CommandEmpty>{t('No Insurance found.')}</CommandEmpty>
                            <CommandGroup>
                              {insucont.data
                                ?.sort((a, b) => a.insurancename.localeCompare(b.insurancename))
                                .filter(ins => 
                                  ins.insurancename.toLowerCase().includes(insuranceSearch.toLowerCase())
                                )
                                .map((ins) => (
                                  <CommandItem
                                    key={ins.id}
                                    onSelect={() => {
                                      field.onChange(ins.id.toString());
                                      setInsurco_id(ins.id);
                                      setOpenInsurance(false);
                                    }}
                                    className="cursor-pointer"
                                  >
                                    {ins.insurancename}
                                    <Check
                                      className={cn(
                                        "ml-auto",
                                        field.value === ins.id.toString() ? "opacity-100" : "opacity-0"
                                      )}
                                    />
                                  </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                </FormItem>
              )}
            />
            <Button type="submit" className="w-full sm:w-auto">
              <FaSave className="mr-2 h-4 w-4" />
              {t('Save')}
            </Button>
          </form>
        </Form>
      </div>

      <Separator className="my-8" />

      <div className="mt-6">
        <div className="flex items-center py-4 gap-2">
          <Input
            placeholder={t("Search Insurance")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="max-w-sm"
          />
          <div className="ml-auto text-sm text-muted-foreground">
            {assigninsu.data?.length || 0} {t("total insurances")}
          </div>
        </div>
        <div className="rounded-md border mb-8">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead 
                  className="cursor-pointer"
                  onClick={() => onSort('insurancename')}
                >
                  <div className="flex items-center">
                    {t('Insurance Name')}
                    {getSortIcon('insurancename')}
                  </div>
                </TableHead>
                <TableHead>{t('Actions')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedInsurances?.length ? (
                sortedInsurances.map(insurance => (
                  <TableRow key={insurance.id}>
                    <TableCell className="font-medium">{insurance.insurancename}</TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">{t("OpenMenu")}</span>
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>{t("Actions")}</DropdownMenuLabel>
                          <DropdownMenuItem onClick={() => desassign(insurance.id, contractor_id)}>
                            <FaReply className="mr-2 h-4 w-4" />
                            {t("Unassign")}
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} className="h-24 text-center">
                    {t('NoInsurancesAssigned')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}