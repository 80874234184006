"use client"

import { MoreHorizontal } from "lucide-react"
import { Button } from "@/components/ui/button"
import { StatusLight } from "../../UI/StatusLight"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

// Helper function to check if claim number exists
const hasClaimNumber = (claimNumber) => {
  return claimNumber && claimNumber !== "0";
};

export const columns = ({ t, temp, timeclaim }) => [
  {
    accessorKey: "address",
    header: t("Address"),
    cell: ({ row }) => {
      const claim = row.original
      return (
        <div 
          onClick={() => window.location.href = "/lareas/" + claim.id}
          className="w-[280px] min-w-[280px] max-w-[280px]"
        >
          <p className="text-base font-semibold truncate">
            {claim.address.split(",")[0]}
          </p>
          <p className="text-sm font-normal text-muted-foreground truncate">
            {claim.address.substring(claim.address.indexOf(",") + 1)}
          </p>
          {hasClaimNumber(claim.claim_number) && (
            <p className="text-sm font-normal text-muted-foreground truncate">
              {t('ClaimNumber')}: {claim.claim_number}
            </p>
          )}
        </div>
      )
    },
  },
  {
    accessorKey: "status",
    header: t("Status"),
    filterFn: (row, id, value) => {
      const status = row.getValue(id);
      // When value is "A" or undefined, show all rows
      if (!value || value === "A") return true;
      // For "N" show in progress, for "F" show finished
      return status === value;
    },
    cell: ({ row }) => {
      const claim = row.original
      const claimTimeData = timeclaim && timeclaim.data && timeclaim.data.data;
      const claimTime = claimTimeData ? claimTimeData.find(tc => tc && tc.claim === claim.id) : null;
      
      return (
        <div className="flex flex-col w-[150px] min-w-[150px]">
          <div className="flex flex-row items-center text-base font-medium">
            {claim.status === "N" ? (
              <>
                <StatusLight status="progress" disabled={false} />
                {t('InProgress')}
              </>
            ) : (
              <>
                <StatusLight status="finished" disabled={false} />
                {t('Finished')}
              </>
            )}
          </div>
          <div>
            <p className="text-sm font-normal text-muted-foreground">
              {t('Elapsed')}: {parseFloat(claimTime?.tlacl || 0).toFixed(2)}h
            </p>
          </div>
          
          {/* Status indicators for certificate and invoice - only visible to admin users */}
          {temp === '1' && (
            <div className="flex flex-row items-center mt-1">
              {/* Certificate status indicator */}
              <div className="flex items-center mr-2" title={hasClaimNumber(claim.claim_number) ? t('HasCertificate') : t('NoCertificate')}>
                <StatusLight 
                  status={hasClaimNumber(claim.claim_number) ? "certificate" : "inactive"} 
                  disabled={false} 
                />
                <span className="text-sm font-normal text-muted-foreground">{t('Cert')}</span>
              </div>
              
              {/* Invoice status indicator */}
              <div className="flex items-center" title={claim.isInvoiced ? t('Invoiced') : t('NotInvoiced')}>
                <StatusLight 
                  status={claim.isInvoiced ? "invoice" : "inactive"} 
                  disabled={false} 
                />
                <span className="text-sm font-normal text-muted-foreground">{t('Inv')}</span>
              </div>
            </div>
          )}
        </div>
      )
    },
  },
  {
    accessorKey: "created_at",
    header: t("DateCreated"),
    cell: ({ row }) => {
      const dateUTC = new Date(row.original.created_at + 'Z')
      const currentdate = dateUTC.toLocaleString(
        "en-CA",
        {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        }
      )
      return (
        <div className="text-sm font-normal text-muted-foreground w-[200px] min-w-[200px]">
          {currentdate}
        </div>
      )
    },
  },
  {
    accessorKey: "contractor_name",
    header: t("Contractor"),
    cell: ({ row }) => (
      <div className="text-sm text-muted-foreground w-[150px] min-w-[150px]">
        {row.original.contractor_name || row.original.name || t('Unknown')}
      </div>
    ),
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const claim = row.original

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">{t("OpenMenu")}</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t('Actions')}</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => window.location.href = "/lareas/" + claim.id}
            >
              {t('ListAreas')}
            </DropdownMenuItem>

            {claim.status !== "F" && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => window.location.href = "/ejob/" + claim.id + "/" + claim.contractor_id}
                >
                  {t('EditJob')}
                </DropdownMenuItem>
              </>
            )}

            {claim.status !== "F" && claim.status === "N" && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => window.location.href = "/addArea/" + claim.id}
                >
                  {t('AddArea')}
                </DropdownMenuItem>
              </>
            )}

            {claim.status !== "F" && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => window.location.href = "/genpub/" + claim.contractor_id + "/" + claim.id}
                >
                  {t('QR')}
                </DropdownMenuItem>
              </>
            )}

            {claim.status === "F" && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => window.location.href = "/certificate2/" + claim.id}
                >
                  {t('GenerateCertificate')}
                </DropdownMenuItem>
                {temp === '1' && (
                  <DropdownMenuItem
                    onClick={() => window.location.href = "/lcosts/" + claim.id}
                  >
                    {t('CheckCosts')}
                  </DropdownMenuItem>
                )}
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
] 