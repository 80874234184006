import React, { useState, useEffect } from "react";
import Areap from "../AreasPub/Areap";
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
} from "@/components/ui/table";
import Allfetch from "../Allfetch";

const para = window.location.pathname.split('/')    // para[2] es el enlace del trabajo

export default function Viewpub () {

  const [link, setLink] = useState([])
  const [areas, setAreas] = useState([])
  const [times, setTimes] = useState([])

  useEffect(() => {
    Allfetch('/getlink/' + para[2], setLink)
  }, [])

  useEffect(() => {
    Allfetch('/linkareas/' + link?.id, setAreas)
    Allfetch('/timelapsedPub/' + link?.id, setTimes)
  }, [link])

  if (areas !== undefined) {
    return (
      <div className="container">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>NAME</TableHead>
              <TableHead>STATUS</TableHead>
              <TableHead>TIME BREAKDOWN</TableHead>
              <TableHead>DRYING SETUP</TableHead>
              <TableHead>ROBBIE ID</TableHead>
              <TableHead className="action-header">ACTIONS</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            { areas.map(area => <Areap area={area} key={area.id} times={times} link1={para[2]} link2={area.link} />) }
          </TableBody>
        </Table>
      </div> 
    )
  }
}
