import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button.jsx";
import {
  Form,
  FormField,
  FormLabel,
  FormControl,
  FormItem,
} from "@/components/ui/form.jsx"
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from "@/components/ui/table.jsx";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem
} from "@/components/ui/select.jsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb.jsx";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { object, number} from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaSave, FaReply } from "react-icons/fa";
import { MoreHorizontal, Check, ChevronsUpDown, ArrowUpDown, ArrowUp, ArrowDown } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import config_ip from '../../../config_ip';
import { useAllFetch } from '../../CustomHooks/useAllFetch';
import { useAuth } from "@clerk/clerk-react"
import { useTranslation } from "react-i18next";
import { Loading } from "../../UI/Loading";
import { cn } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";

const contractor_id = window.location.pathname.split('/')[2]
const contra_name = window.location.pathname.split('/')[3]

export default function Assignrobbie () {
  const { t } = useTranslation();
  const [robbie_id, setRobbie_id] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [statusFilter, setStatusFilter] = useState("all")
  const [robbieSearch, setRobbieSearch] = useState("");
  const [openRobbie, setOpenRobbie] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const { getToken } = useAuth()

  const robbcont = useAllFetch(['robbcont'], '/robbcont/' + contractor_id)
  const assignrobbcont = useAllFetch(['assignrobbcont'], '/assignrobbcont/' + contractor_id)
  
  const onSubmit = async (data) => {
    const tokenu = await getToken()
    await fetch(config_ip.url + "/assignrobbie", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenu}`
      },
    });
    window.location.pathname = "/assignrobbie/" + contractor_id + "/" + contra_name;
  }

  const desassign = async (robbie_id, contractor_id) => {
    const tokenu = await getToken()
    await fetch(config_ip.url + '/desassign/' + robbie_id + '/' + contractor_id, {
      method: "get",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenu}`
      },
    });
    window.location.pathname = "/assignrobbie/" + contractor_id + "/" + contra_name;
  }

  const assignRobbieSchema = object().shape({
    robbie_id: number(),
  })

  const form = useForm({
    resolver: yupResolver(assignRobbieSchema),
    defaultValues: {
      robbie_id: 0,
      contractor_id: contractor_id,
    },
    values: {
      robbie_id,
      contractor_id,
    },
  })

  const onSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedRobbies = (robbies) => {
    if (!sortConfig.key) return robbies;

    return [...robbies].sort((a, b) => {
      if (sortConfig.key === 'name_raspi') {
        return sortConfig.direction === 'asc' 
          ? a.name_raspi.localeCompare(b.name_raspi)
          : b.name_raspi.localeCompare(a.name_raspi);
      }
      if (sortConfig.key === 'status') {
        return sortConfig.direction === 'asc'
          ? a.status.localeCompare(b.status)
          : b.status.localeCompare(a.status);
      }
      return 0;
    });
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown className="ml-2 h-4 w-4" />;
    }
    return sortConfig.direction === 'asc' 
      ? <ArrowUp className="ml-2 h-4 w-4" />
      : <ArrowDown className="ml-2 h-4 w-4" />;
  };

  if (robbcont.isPending || assignrobbcont.isPending) {
    return <Loading />;
  }

  const filteredRobbies = assignrobbcont.data?.filter(robbie => {
    const matchesSearch = robbie.name_raspi.toLowerCase().includes(searchTerm.toLowerCase())
    const matchesStatus = statusFilter === "all" || robbie.status === statusFilter
    return matchesSearch && matchesStatus
  })

  const sortedRobbies = getSortedRobbies(filteredRobbies || []);

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-4">{contra_name.replaceAll("%20", " ")}</div>
      <div className="mb-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={"/jobs/" + contractor_id}>{t('Claims')}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={"/lcontractors"}>{t('List Contractor')}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>{t('AssignRobbies')}</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="mt-6 md:mt-8">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="robbie_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('Assign Robbie')}</FormLabel>
                  <FormControl>
                    <Popover open={openRobbie} onOpenChange={setOpenRobbie}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={openRobbie}
                          className="w-full justify-between"
                        >
                          {field.value ? 
                            robbcont.data?.find(rob => rob.id.toString() === field.value)?.name_raspi 
                            : t('Select Robbie Id')}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="p-0" style={{ width: 'var(--radix-popover-trigger-width)' }}>
                        <Command shouldFilter={false}>
                          <CommandInput 
                            placeholder={t('Search Robbie Id...')}
                            value={robbieSearch}
                            onValueChange={setRobbieSearch}
                            className="border-none"
                          />
                          <CommandList>
                            <CommandEmpty>{t('No Robbie Id found.')}</CommandEmpty>
                            <CommandGroup>
                              {robbcont.data
                                ?.sort((a, b) => a.name_raspi.localeCompare(b.name_raspi))
                                .filter(rob => 
                                  rob.name_raspi.toLowerCase().includes(robbieSearch.toLowerCase())
                                )
                                .map((rob) => (
                                  <CommandItem
                                    key={rob.id}
                                    onSelect={() => {
                                      field.onChange(rob.id.toString());
                                      setRobbie_id(rob.id);
                                      setOpenRobbie(false);
                                    }}
                                    className="cursor-pointer"
                                  >
                                    {rob.name_raspi}
                                    <Check
                                      className={cn(
                                        "ml-auto",
                                        field.value === rob.id.toString() ? "opacity-100" : "opacity-0"
                                      )}
                                    />
                                  </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                </FormItem>
              )}
            />
            <Button type="submit" className="w-full sm:w-auto">
              <FaSave className="mr-2 h-4 w-4" />
              {t('Save')}
            </Button>
          </form>
        </Form>
      </div>

      <Separator className="my-8" />

      <div className="mt-6">
        <div className="flex items-center py-4 gap-2">
          <Input
            placeholder={t("Search Robbie")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="max-w-sm"
          />
          <Select
            value={statusFilter}
            onValueChange={setStatusFilter}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder={t("FilterByStatus")} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">
                {t("All")}
              </SelectItem>
              <SelectItem value="A">
                {t("Available")}
              </SelectItem>
              <SelectItem value="I">
                {t("In Area")}
              </SelectItem>
            </SelectContent>
          </Select>
          <div className="ml-auto text-sm text-muted-foreground">
            {assignrobbcont.data?.filter(r => r.status === "I").length || 0} {t("in area")},
            {" "}
            {assignrobbcont.data?.filter(r => r.status === "A").length || 0} {t("available")},
            {" "}
            {assignrobbcont.data?.length || 0} {t("total")}
          </div>
        </div>
        <div className="rounded-md border mb-8">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead 
                  className="cursor-pointer"
                  onClick={() => onSort('name_raspi')}
                >
                  <div className="flex items-center">
                    {t('RobbieID')}
                    {getSortIcon('name_raspi')}
                  </div>
                </TableHead>
                <TableHead 
                  className="cursor-pointer"
                  onClick={() => onSort('status')}
                >
                  <div className="flex items-center">
                    {t('Status')}
                    {getSortIcon('status')}
                  </div>
                </TableHead>
                <TableHead>{t('Actions')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedRobbies?.length ? (
                sortedRobbies.map(robbie => (
                  <TableRow key={robbie.id}>
                    <TableCell className="font-medium">{robbie.name_raspi}</TableCell>
                    <TableCell>
                      <Badge 
                        variant={robbie.status === "I" ? "default" : "outline"}
                        className="capitalize"
                      >
                        {robbie.status === "I" ? t("In Area") : t("Available")}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">{t("OpenMenu")}</span>
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>{t("Actions")}</DropdownMenuLabel>
                          <DropdownMenuItem onClick={() => desassign(robbie.id, contractor_id)}>
                            <FaReply className="mr-2 h-4 w-4" />
                            {t("Unassign")}
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} className="h-24 text-center">
                    {t('NoRobbiesAssigned')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
