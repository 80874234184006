import React, { useState, useEffect, useMemo } from "react";
import "../linechart.scss";
import Plot from "react-plotly.js";
import Viewbox from "../UI/Viewbox";
import Telemetrics from "../UI/AreaTelemetrics/index.jsx";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { Loading } from "../UI/Loading";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";

const arraySlash = window.location.pathname.split('/')
const jobId = arraySlash[2]
const areaId = arraySlash[3]

export default function LinegraphPub(props) {

  // Memoize the options object to prevent it from changing on every render
  const options = useMemo(() => ({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Use the browser's timezone
  }), []);

  const [isDataSampled, setIsDataSampled] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const area = useAllFetch(['area'], '/getareaPub/' + areaId)
  const events = useAllFetch(['events'], '/geteventsPub/' + areaId)
  const daily = useAllFetch(['daily'], '/dailyPub/' + jobId + '/' + areaId)
  const current = useAllFetch(['current'], '/currentPub/' + jobId + '/' + areaId)
  const lcountxarea = useAllFetch(['lcountxarea'], '/lcountxareaPub/' + areaId)
  const pictures = useAllFetch(['pictures'], '/getPicturesPub/' + jobId + '/' + areaId)

  // Wrap pausedate and pausewhy in their own useMemo hook to fix the dependency warning
  const { pausedate, pausewhy } = useMemo(() => {
    const pauseDateArray = [];
    const pauseWhyArray = [];
    
    // Process events data
    if (daily.data && daily.data.length > 0 && events.data) {
      for (let j = 0; j < events.data?.length; j = j + 1) {
        if (events.data?.[j].why !== 'Restart area') {
          // Append 'Z' to indicate UTC time, which will be converted to local time
          const fechaLocal = new Date(events.data?.[j].created_at + 'Z');
          pauseDateArray.push(fechaLocal);
          pauseWhyArray.push(events.data?.[j].why);
        }
      }
    }
    
    return { pausedate: pauseDateArray, pausewhy: pauseWhyArray };
  }, [daily.data, events.data]);

  // Get setpoint from area data
  const setpoint = useMemo(() => {
    let result = 0.0;
    
    if (area?.data && area.data[0]) {
      const descriptions = area.data[0].jobtemp;
      if (descriptions) {
        descriptions.forEach((element) => {
          result = element.humisetpoint || 0.0;
        });
      }
    }
    
    return result;
  }, [area.data]);

  useEffect(() => {
    if (daily.data && daily.data.length > 0) {
      // Calculate how many data points are needed for 3.2 days of minute-by-minute logs
      // 3.2 days * 24 hours * 60 minutes = 4608 data points
      const MIN_FULL_RESOLUTION_POINTS = 4608;
      
      setIsDataSampled(daily.data.length > MIN_FULL_RESOLUTION_POINTS);
    }
  }, [daily.data]);

  // Add effect to set data loaded state
  useEffect(() => {
    if (
      !area.isLoading && 
      !events.isLoading && 
      !daily.isLoading && 
      !lcountxarea.isLoading && 
      !pictures.isLoading && 
      !current.isLoading &&
      area.data && 
      area.data[0] &&
      daily.data &&
      daily.data.length > 0
    ) {
      setIsDataLoaded(true);
    } else {
      setIsDataLoaded(false);
    }
  }, [
    area.isLoading, events.isLoading, daily.isLoading, 
    lcountxarea.isLoading, pictures.isLoading, current.isLoading,
    area.data, daily.data
  ]);

  // Create a memoized version of the processed data to avoid recalculations
  const processedData = useMemo(() => {
    if (!daily.data) return { datedata: [], humidata: [], tempdata: [], amperdata: [], powerdata: [], energydata: [], moisturedata: [], firstdate: null, lastdate: null };
    
    const data = daily.data;
    
    // Helper function to ensure dates are properly parsed in local timezone
    const parseDate = (dateString) => {
      if (!dateString) return new Date();
      
      // Append 'Z' to indicate UTC time, which will be converted to local time
      return new Date(dateString + 'Z');
    };
    
    const result = {
      datedata: [],
      humidata: [],
      tempdata: [],
      amperdata: [],
      powerdata: [],
      energydata: [],
      moisturedata: [],
      firstdate: parseDate(data[0].date),
      lastdate: parseDate(data[data.length - 1].date)
    };
    
    // Calculate how many data points are needed for 3.2 days of minute-by-minute logs
    const MIN_FULL_RESOLUTION_POINTS = 4608;
    
    let sampledData = data;
    
    if (data.length > MIN_FULL_RESOLUTION_POINTS) {
      // Get the timestamp for 3.2 days ago from the most recent data point
      const mostRecentDate = parseDate(data[data.length - 1].date);
      const thresholdDate = new Date(mostRecentDate);
      thresholdDate.setDate(thresholdDate.getDate() - 3.2);
      
      // Split the data into recent (full resolution) and older (to be sampled)
      const recentData = [];
      const olderData = [];
      
      for (let i = 0; i < data.length; i++) {
        const currentDate = parseDate(data[i].date);
        if (currentDate >= thresholdDate) {
          recentData.push(data[i]);
        } else {
          olderData.push(data[i]);
        }
      }
      
      // Sample the older data if there's any
      let sampledOlderData = [];
      if (olderData.length > 0) {
        const MAX_OLDER_POINTS = 500; // Limit for older data points
        const samplingRate = Math.ceil(olderData.length / MAX_OLDER_POINTS);
        sampledOlderData = olderData.filter((_, index) => index % samplingRate === 0);
        
        // Always include the first point from older data
        if (sampledOlderData.length > 0 && sampledOlderData[0] !== olderData[0]) {
          sampledOlderData.unshift(olderData[0]);
        }
      }
      
      // Combine the sampled older data with the full resolution recent data
      sampledData = [...sampledOlderData, ...recentData];
    }
    
    // Process the sampled data
    for (let i = 0; i < sampledData.length; i++) {
      const item = sampledData[i];
      if (item) {
        // Convert date string to local date object
        const fechaLocal = parseDate(item.date);
        result.datedata.push(fechaLocal);
        result.humidata.push(item.humi);
        result.tempdata.push(item.temp);
        result.amperdata.push(item.amper_ef);
        result.powerdata.push(item.power / 1000);
        result.energydata.push(item.energy);
        result.moisturedata.push(item.moisture);
      }
    }
    
    return result;
  }, [daily.data]);

  // Use the processed data in the component
  const { datedata, humidata, tempdata, amperdata } = processedData;
  const { firstdate, lastdate } = processedData;

  // Memoize the current data processing to prevent it from disappearing on reload
  const { currentData, timeelapsed: calculatedTimeElapsed } = useMemo(() => {
    let currentTemp = 0;
    let currentHumi = 0;
    let currentAmper = 0;
    let currentPower = 0;
    let currentEnergy = 0;
    let currentDate = "";
    let dewPoint = 0;
    let timeElapsed = "0 Hours 0 Minutes";
    
    // Helper function to format dates consistently
    const formatDate = (dateString) => {
      if (!dateString) return new Date().toLocaleString('en-US', options);
      
      // Create a date object with 'Z' to indicate UTC, which will be converted to local time
      const date = new Date(dateString + 'Z');
      return date.toLocaleString('en-US', options);
    };
    
    // Process current data
    if (daily.data && daily.data.length > 0) {
      // Get the latest data point for fallbacks
      const latestDailyPoint = daily.data[daily.data.length - 1];
      
      if (current.data && current.data[0]) {
        const data1 = current.data[0];
        currentTemp = data1.temp || 0;
        currentHumi = data1.humi || 0;
        currentAmper = data1?.amper_ef || 0;
        currentEnergy = data1?.energy || 0;
        currentPower = data1?.power || 0;
        // Format date using the browser's local timezone
        currentDate = formatDate(data1.date);
        
        // Calculate dewpoint
        const rh = currentHumi;
        const temp = currentTemp;
        if (rh > 0 && temp !== undefined) {
          const h = (Math.log10(rh) - 2.0) / 0.4343 + (17.62 * temp) / (243.12 + temp);
          dewPoint = (243.12 * h / (17.62 - h));
        }
      } else {
        // Fallback to daily data
        currentTemp = latestDailyPoint.temp || 20;
        currentHumi = latestDailyPoint.humi || 45;
        currentAmper = latestDailyPoint.amper_ef || 0;
        currentEnergy = latestDailyPoint.energy || 0;
        currentPower = latestDailyPoint.power || 0;
        // Format date using the browser's local timezone
        currentDate = formatDate(latestDailyPoint.date);
        
        // Calculate dewpoint
        const rh = currentHumi;
        const temp = currentTemp;
        if (rh > 0 && temp !== undefined) {
          const h = (Math.log10(rh) - 2.0) / 0.4343 + (17.62 * temp) / (243.12 + temp);
          dewPoint = (243.12 * h / (17.62 - h));
        }
      }
      
      // Calculate time elapsed
      if (firstdate && lastdate) {
        timeElapsed = timeConversion(Date.parse(lastdate) - Date.parse(firstdate));
      }
    }
    
    return {
      currentData: {
        humi: currentHumi,
        temp: currentTemp,
        amper_ef: currentAmper,
        power: currentPower,
        energy: currentEnergy,
        dewpoint: dewPoint,
        date: currentDate
      },
      timeelapsed: timeElapsed
    };
  }, [daily.data, current.data, firstdate, lastdate, options]);

  function timeConversion(millisec) {
    var minutes = (millisec / (1000 * 60.0)).toFixed(3);
    var hours = (millisec / (1000 * 60.0 * 60.0)).toFixed(3);
    var days = (millisec / (1000 * 60.0 * 60.0 * 24)).toFixed(3);

    let realdays = 0
    let realhours = 0
    let realminutes = 0
    if (days > 1) {
      realdays = days.toString().split('.')[0];
      hours = days.toString().split('.')[1] * 2.4 / 100
    }
    if (hours < 24) {
      realhours = hours.toString().split('.')[0];
      hours = parseFloat(hours).toFixed(3)
      minutes = hours.toString().split('.')[1] * 6.0 / 100
    }
    if (minutes < 60) {
      realminutes = minutes.toString().split('.')[0];
    }
    if (realdays < 1) {
      return realhours + " Hours " + realminutes + " Minutes"
    } else {
      return realdays + " Days " + realhours + " Hours " + realminutes + " Minutes"
    }
  }

  // Create plot traces and layout inside useMemo to fix the dependency warning
  const { plotComponents } = useMemo(() => {
    // Create plot traces - always create them even if empty
    const humidity_traces = [];
    
    if (datedata && datedata.length > 0) {
      // Add main traces
      humidity_traces.push(
        {
          name: "Relative Humidity (%)",
          x: datedata,
          y: humidata,
          mode: "lines",
          line: { shape: "spline" },
          type: "scatter",
          smoothing: 1.3,
          marker: { color: "#54DED0" },
          hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>%{y:.1f}%<extra>Relative Humidity</extra>'
        },
        {
          name: "Relative Humidity Shadow",
          showlegend: false,
          x: datedata,
          y: humidata,
          mode: "lines",
          line: { shape: "spline", width: 10 },
          type: "scatter",
          smoothing: 1.3,
          marker: { color: "#54ded033" },
          hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>%{y:.1f}%<extra>Relative Humidity</extra>',
          hoverinfo: 'skip'
        },
        {
          name: "Temperature (°C)",
          x: datedata,
          y: tempdata,
          mode: "lines",
          line: { shape: "spline" },
          type: "scatter",
          smoothing: 1.3,
          marker: { color: "#ff76004f" },
          hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>%{y:.1f}°C<extra>Temperature</extra>'
        },
        {
          name: "Temperature Shadow(°C)",
          showlegend: false,
          x: datedata,
          y: tempdata,
          mode: "lines",
          line: { shape: "spline", width: 10 },
          type: "scatter",
          smoothing: 1.3,
          marker: { color: "#ff760014" },
          hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>%{y:.1f}°C<extra>Temperature</extra>',
          hoverinfo: 'skip'
        },
        {
          name: "Setpoint (%)",
          x: [firstdate, lastdate],
          y: [setpoint, setpoint],
          mode: "lines",
          type: "scatter",
          line: {
            dash: "dot",
            width: 1,
          },
          marker: { color: "#DDDDDD" },
          hovertemplate: 'Setpoint: %{y:.1f}%<extra>Setpoint</extra>'
        },
        {
          name: "Electrical Current (A)",
          x: datedata,
          y: amperdata,
          mode: "lines",
          line: { shape: "linear" },
          smoothing: 1.3,
          type: "scatter",
          marker: { color: "#F2D027" },
          hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>%{y:.2f}A<extra>Electrical Current</extra>'
        },
        {
          name: "Electrical Current Shadow (A)",
          showlegend: false,
          x: datedata,
          y: amperdata,
          mode: "lines",
          line: { shape: "linear", width: 10 },
          smoothing: 1.3,
          type: "scatter",
          marker: { color: "#f2d02742" },
          hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>%{y:.2f}A<extra>Electrical Current</extra>',
          hoverinfo: 'skip'
        }
      );
      
      // Add pause traces
      const pause_tracesh = [];
      for (let i = 0; i < pausedate.length; i++) {
        pause_tracesh.push({
          name: "Paused",
          showlegend: i === 0,
          x: [pausedate[i], pausedate[i]],
          y: [1, 80],
          mode: "lines+markers",
          line: {
            dash: "dot",
            width: 2,
          },
          type: "scatter",
          marker: { color: "black" },
          text: pausewhy[i],
          textposition: "middle right",
          hovertemplate: '%{x|%b %d, %Y, %I:%M:%S %p}<br>' + pausewhy[i] + '<extra>Paused</extra>'
        });
      }
      
      humidity_traces.push(...pause_tracesh);
    }
    
    // Create plot layout
    const layout = {
      margin: {
        l: 50,
        r: 60,
        b: 50,
        t: 50,
        pad: 4
      },
      yaxis: { range: [0, 100], color: '#9E9E9E' },
      xaxis: {
        autorange: true,
        color: '#9E9E9E',
        // Only keep hoverformat for tooltips
        hoverformat: '%b %d, %Y, %I:%M:%S %p'
      },
      showlegend: true,
      legend: {
        bgcolor: 'rgba(0,0,0,0)',
        itemclick: false,
        itemdoubleclick: false,
        x: 1,
        xanchor: 'right',
      },
      annotations: isDataSampled ? [
        {
          text: 'Data older than 3.2 days is sampled for performance',
          showarrow: false,
          xref: 'paper',
          yref: 'paper',
          x: 0.98,
          y: 0.02,
          xanchor: 'right',
          yanchor: 'bottom',
          font: {
            size: 10,
            color: '#9E9E9E'
          },
          bgcolor: 'rgba(255, 255, 255, 0.7)',
          bordercolor: '#ddd',
          borderwidth: 1,
          borderpad: 4,
          align: 'right'
        }
      ] : []
    };
    
    // Create the Plot component
    const plotComp = (
      <Plot
        data={humidity_traces}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        layout={layout}
        config={{
          responsive: true,
          displayModeBar: true,
          scrollZoom: true,
          modeBarButtonsToRemove: ['lasso2d', 'select2d'],
          displaylogo: false,
          // Ensure local timezone is used for date display
          locales: {
            'en-US': {
              format: {
                days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                date: '%b %e, %Y',
                datetime: '%b %e, %Y, %I:%M:%S %p',
                hours: '%I %p',
                minutes: '%I:%M %p',
                seconds: '%I:%M:%S %p'
              }
            }
          },
          locale: 'en-US'
        }}
      />
    );
    
    return { plotComponents: plotComp };
  }, [datedata, humidata, tempdata, amperdata, firstdate, lastdate, setpoint, pausedate, pausewhy, isDataSampled]);
  
  // Show loading state
  if (!isDataLoaded) {
    return <Loading />;
  }

  // Debug area data to find dehumidifier count
  console.log("AREA DATA:", area.data[0]);
  console.log("LCOUNTXAREA DATA:", lcountxarea.data);
  console.log("CURRENT DATA:", currentData);
  
  // Get dehumidifier count from the correct source
  const dehumidifierCount = lcountxarea.data?.dh || area.data[0]?.dehumidifiers || area.data[0]?.dh || 0;

  // Create the telemetrics component directly
  const telemetricsComponent = (
    <Telemetrics
      current_humidity={currentData.humi}
      current_temperature={currentData.temp}
      current_dewpoint={currentData.dewpoint}
      current_amperage={currentData.amper_ef}
      current_power={currentData.power}
      current_energy={currentData.energy}
      timeelapsed={calculatedTimeElapsed}
      last_date_updated={currentData.date}
      airmovers={area.data[0]?.blowers || 0}
      dehumidifiers={dehumidifierCount}
    />
  );

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-2">{area.data[0].aname}</div>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Claims</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
            {jobId.length === 64 ?
              <BreadcrumbLink href={"/linktemp/" + jobId}>Areas</BreadcrumbLink>
              :
              <BreadcrumbLink href={"/lareas/" + jobId}>Areas</BreadcrumbLink>
            }
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>Area</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      
      {/* Main content container */}
      <div className="tab-container">
        {/* Graph section */}
        <div className="plot-1">
          {plotComponents}
        </div>
        
        <div className="text-muted-foreground text-sm italic mb-2">
          {area.data?.[0].description} 
        </div>
        
        {/* Telemetrics section */}
        {telemetricsComponent}
      </div>

      <div className="mt-8">
        {!pictures.isLoading && pictures.data?.length > 0 ? (
          <div>
            <h2 className="text-xl font-semibold mb-4">Documentation</h2>
            <div className="claim-pictures">
              {pictures.data.map((file) => (
                <Viewbox
                  key={file.filename}
                  file={file}
                  jobid={jobId}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
