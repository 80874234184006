import React, { useEffect, useCallback, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { useAllFetch } from '../CustomHooks/useAllFetch';
import { Popup } from 'mapbox-gl';
import config_ip from '../../config_ip.js';
import { useSearchParams } from 'react-router-dom';

mapboxgl.accessToken = config_ip.mapboxgl_token;

export default function SudoMap(props) {
  const mapContainer = useRef('');
  const [searchParams] = useSearchParams();
  const contractor_id = searchParams.get('contractor_id');
  const insurance_id = searchParams.get('insurance_id');
  
  const pointsmap = useAllFetch(['sudomap'], `/sudomap/claims?contractor_id=${contractor_id}&insurance_id=${insurance_id}`);

  const createGeoJSON = (pointsmap) => {
    const geoJSON = {
      type: 'FeatureCollection',
      features: []
    };
    
    if (!pointsmap.data) return geoJSON;

    // Handle both grouped and ungrouped data
    const claims = Array.isArray(pointsmap.data) 
      ? pointsmap.data 
      : pointsmap.data.flatMap(group => group.claims);

    claims.forEach((claim) => {
      const feature = {
        type: 'Feature',
        properties: {
          ...claim,
          group: claim.group || 'All Claims'
        },
        geometry: {
          type: 'Point',
          coordinates: [claim.lng, claim.lat]
        }
      };
      geoJSON.features.push(feature);
    });
    return geoJSON;
  };

  const viewMap = useCallback(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/robbierestorationtech/cl1zq3yrj000415pc2f3qzen5',
      center: [-79.38, 43.65],
      zoom: 10,
    });

    map.on('load', () => {
      // Add municipality boundaries layer
      map.addSource('municipality-boundaries', {
        type: 'vector',
        url: 'mapbox://mapbox.boundaries-adm2-v3'
      });

      map.addLayer({
        'id': 'municipality-borders',
        'type': 'line',
        'source': 'municipality-boundaries',
        'source-layer': 'boundaries_admin_2',
        'layout': {},
        'paint': {
          'line-color': '#627BC1',
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            2,
            1
          ],
          'line-opacity': 0.6
        },
        // Filter for Ontario (CA-ON)
        'filter': ['all',
          ['==', ['get', 'iso_3166_1'], 'CAN'],
          ['==', ['get', 'iso_3166_2'], 'CA-ON']
        ]
      });

      // Add hover effect for municipalities
      let hoveredStateId = null;

      map.on('mousemove', 'municipality-borders', (e) => {
        if (e.features.length > 0) {
          if (hoveredStateId !== null) {
            map.setFeatureState(
              { source: 'municipality-boundaries', sourceLayer: 'boundaries_admin_2', id: hoveredStateId },
              { hover: false }
            );
          }
          hoveredStateId = e.features[0].id;
          map.setFeatureState(
            { source: 'municipality-boundaries', sourceLayer: 'boundaries_admin_2', id: hoveredStateId },
            { hover: true }
          );
        }
      });

      map.on('mouseleave', 'municipality-borders', () => {
        if (hoveredStateId !== null) {
          map.setFeatureState(
            { source: 'municipality-boundaries', sourceLayer: 'boundaries_admin_2', id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = null;
      });

      map.addSource('claims', {
        type: 'geojson',
        data: createGeoJSON(pointsmap),
        cluster: true,
        clusterMaxZoom: 20,
        clusterRadius: 13
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'claims',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15,
            100,
            20,
            750,
            30
          ]
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'claims',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'claims',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.getSource('claims').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
      });

      map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = '';
      });

      map.on('click', 'unclustered-point', (e) => {
        const claim = e.features[0].properties;
        new Popup()
          .setHTML(`
            <div class="bg-white p-4 rounded shadow">
              <h3 class="text-lg font-bold mb-2">${claim.address}</h3>
              <p class="mb-1"><b>Group:</b> ${claim.group}</p>
              <p class="mb-1"><b>Contractor:</b> ${claim.contractor_name || 'N/A'}</p>
              <p class="mb-1"><b>Insurance:</b> ${claim.insurance_name || 'N/A'}</p>
              <p class="mb-1"><b>Postal Code:</b> ${claim.postalcode || 'N/A'}</p>
              <p class="mb-1"><b>Status:</b> ${claim.status || 'N/A'}</p>
              <p class="mb-1"><b>Created:</b> ${new Date(claim.created_at).toLocaleDateString()}</p>
            </div>
          `)
          .setLngLat(e.features[0].geometry.coordinates)
          .addTo(map);
      });

      map.on('mouseenter', 'unclustered-point', () => {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'unclustered-point', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [pointsmap]);

  useEffect(() => {
    viewMap();
  }, [viewMap]);

  return (
    <div className="absolute top-0 w-screen h-screen">
      <div className="absolute top-0 left-0 w-full h-full" ref={mapContainer} />
    </div>
  );
} 