import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import  Allfetch from "../Allfetch";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import ip from '../../config_ip';
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Loading } from "../UI/Loading";

const temp = localStorage.getItem("temp");
const contractor_id = localStorage.getItem("contractor_id");
const nameuser = localStorage.getItem('name')
const job_id = window.location.pathname.split('/')[2]
let tokenu = ''

export default function AreaForm() {

  const {t } = useTranslation()
  const [statusI, setStatusI] = useState('')
  const [robbieSearch, setRobbieSearch] = useState("");
  const [openRobbie, setOpenRobbie] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { getToken } = useAuth();

  useEffect(() => {
    if (dialogOpen) {
      const timer = setTimeout(() => {
        setDialogOpen(false)
        window.location.pathname = '/lareas/'+ job_id
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [dialogOpen]);

  const robbie = useAllFetch(['robbie'], '/robbie/' + temp + '/' + contractor_id)
  const equipments = useAllFetch(['equipments'], '/lequipDhBl')

  const mutation = useMutation({
    mutationFn: async (data) => {
      tokenu = await getToken()
      const response = await fetch(ip.url + '/insertarea', {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

  const onSubmit = async (data) => {
    data.amperage_expected = 0.0
    if (data.blowers === 0) {
      const dhs = equipments.data[0]?.filter((dh) => 
        dh.id === parseInt(data.equipment_dh_id)
      )
      data.amperage_expected = parseInt(data.dh) * parseFloat(dhs[0].amperage_dh) 
    } else {
      const dhs = equipments.data[0]?.filter((dh) => 
        dh.id === parseInt(data.equipment_dh_id)
      )
      data.amperage_expected = parseInt(data.dh) * parseFloat(dhs[0].amperage_dh)
      const bls = equipments.data[1]?.filter((bl) =>
        bl.id === parseInt(data.equipment_bl_id)
      )
      data.amperage_expected += parseInt(data.blowers) * parseFloat(bls[0].amperage_bl)
    }
    console.log(data)
    mutation.mutate(data,
      {
        onSuccess: async function (json) {
          Allfetch('/chStatusRobbie/I/' + data.name_raspi, setStatusI, null, null, await getToken())
          console.log(statusI)
          setDialogOpen(true)
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
  }

  const areaSchema = object().shape({
    aname: string().min(3, "Must be greater than 2").max(50, "Must be lesser than 50"),
    description: string().min(4),
    name_raspi: string().required(),
    humisetpoint: number()
      .min(2)
      .max(3)
      .lessThan(60.01, "Must be lesser than 60")
      .moreThan(29.99, "Must be grater than or equal 30"),
    equipment_dh_id: string().required(),
    dh: number()
      .min(1, "Must be greater than or equal 1")
      .max(2, "Must be less than 3")
      .typeError("must be a number")
      .lessThan(3),
    equipment_bl_id: string(),
    blowers: number()
      .min(0, "must be greater than or equal 0")
      .max(5, "Must be less than 6")
      .typeError("must be a number")
      .lessThan(6),
//    amperage_expected: number()
  });
  
  const form = useForm({
    resolver: yupResolver(areaSchema),
    values: {
      aname: '',
      description: '',
      name_raspi: '',
      humisetpoint: 30,
      job_id: job_id,
      equipment_dh_id: '',
      dh: 1,
      equipment_bl_id: (10).toString(),
      blowers: 0,
      sensor: 'N',
      user: nameuser,
//      amperage_expected: 0.0,
    },
  });

  if (robbie.isPending || equipments.isPending) {
    return <Loading />;  }

  return (
    <div className="container">
      <div className="mb-3">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>{t('Claims')}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>{t('AddArea')}</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      </div>
      <div className="mt-6 md:mt-8">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="aname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('NameArea')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter area name"
                      {...field}
                      {...form.register("aname")}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('Description')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('EnterAreaDescription')}
                      {...field}
                      {...form.register("description")}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name_raspi"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('RobbieId')}</FormLabel>
                  <FormControl>
                    <Popover open={openRobbie} onOpenChange={setOpenRobbie}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={openRobbie}
                          className="w-full justify-between"
                        >
                          {field.value || t('Select Robbie Id')}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="p-0" style={{ width: 'var(--radix-popover-trigger-width)' }}>
                        <Command shouldFilter={false}>
                          <CommandInput 
                            placeholder={t('Search Robbie Id...')}
                            value={robbieSearch}
                            onValueChange={setRobbieSearch}
                            className="border-none"
                          />
                          <CommandList>
                            <CommandEmpty>{t('No Robbie Id found.')}</CommandEmpty>
                            <CommandGroup>
                              {robbie.data.data
                                ?.sort((a, b) => a.name_raspi.localeCompare(b.name_raspi))
                                .filter(rob => 
                                  rob.name_raspi.toLowerCase().includes(robbieSearch.toLowerCase())
                                )
                                .map((rob) => (
                                  <CommandItem
                                    key={rob.id}
                                    onSelect={() => {
                                      field.onChange(rob.name_raspi);
                                      setOpenRobbie(false);
                                    }}
                                    className="cursor-pointer"
                                  >
                                    {rob.name_raspi}
                                    <Check
                                      className={cn(
                                        "ml-auto",
                                        field.value === rob.name_raspi ? "opacity-100" : "opacity-0"
                                      )}
                                    />
                                  </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="humisetpoint"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('Humisetpoint')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('EnterHumisetpoint')}
                      {...field}
                      {...form.register("humisetpoint")}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="equipment_dh_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('DehumidifierType')}</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={t('SelectHumidifierType')} />
                      </SelectTrigger>
                      <SelectContent>
                        {equipments.data?.[0].map((equipment) => (
                          <SelectItem
                            key={equipment.id}
                            value={equipment.id.toString()}
                          >
                            {equipment.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="dh"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('DehumidifierQty')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Dehumidifiers Qty (1-2)"
                      {...field}
                      {...form.register("dh")}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="equipment_bl_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('AirMoverType')}</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Blower type" />
                      </SelectTrigger>
                      <SelectContent>
                        {equipments.data?.[1].map(
                          (equipment) => (
                            <SelectItem
                              key={equipment.id}
                              value={equipment.id.toString()}
                            >
                              {equipment.name}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
            {form.getFieldState("equipment_bl_id").isDirty && form.getValues("equipment_bl_id") !== 10 &&
              <FormField
                control={form.control}
                name="blowers"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('AirMoverQty')}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Air Mover Qty (0-5)"
                        {...field}
                        {...form.register("blowers")}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            }
            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
              <DialogTrigger asChild>
                <Button type="submit" className="w-full sm:w-auto" disabled={form.formState.isSubmitSuccessful}>{t('CreateArea')}</Button>
              </DialogTrigger>
              {mutation.isSuccess && (
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>{t('Area Created')}</DialogTitle>
                  </DialogHeader>
                  <DialogFooter>
                    <div className="w-full text-center text-sm text-muted-foreground">
                      {t('Redirecting')}
                    </div>
                  </DialogFooter>
                </DialogContent>
              )}
            </Dialog>
          </form>
        </Form>
      </div>
    </div>
  );
}
