import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const Telemetrics = (props) => {

  const { t } = useTranslation()
  const {
    current_humidity,
    current_temperature,
    current_dewpoint,
    current_amperage,
    current_power,
    current_energy,
    timeelapsed,
    last_date_updated,
    airmovers, 
    dehumidifiers
  } = props;

  // Format values with fallbacks
  const formatValue = (value, decimals = 2) => {
    if (value === null || value === undefined) return '0';
    return typeof value === 'number' ? value.toFixed(decimals) : value.toString();
  };

  return (
    <div className="my-3">
      <div className="flex flex-row gap-5">
        <div className="flex gap-2">
          <div>🌵</div>
          <p>{formatValue(current_humidity)} <strong>% {t('RelativeHumidity')}</strong>   </p>
        </div>

        <div className="flex gap-2">
          <div>🌡️</div>
          <p>{formatValue(current_temperature)} <strong>ºC</strong> </p>
        </div>

        <div className="flex gap-2">
          <div>🌧️</div>
          <p>{formatValue(current_dewpoint)} <strong>ºC - {t('DewPoint')}</strong> </p>
        </div>
      </div>

      <div className="flex flex-row gap-5 mb-2">
        <div className="flex gap-2">
          <div>⚡️</div>
          <p> {formatValue(current_amperage)} <strong>Amps</strong> </p>
        </div>

        <div className="flex gap-2">
          <div>🦾</div>
          <p> {typeof current_power === 'number' ? (current_power / 1000).toFixed(3) : '0.000'} <strong>kW</strong> </p>
        </div>

        <div className="flex gap-2">
          <div>🔋</div>
          <p> {formatValue(current_energy)} <strong>kWh</strong></p>
        </div>
      </div>

      <div className="flex flex-row gap-5">
        <div className="flex gap-5">
          <p className="text-muted-foreground italic text-sm"> <strong>{t('AirMover')}:</strong> {airmovers || 0} </p>
          <p className="text-muted-foreground italic text-sm"> <strong>{t('Dehumidifiers')}:</strong> {dehumidifiers || 0} </p>
          <p className="text-muted-foreground italic text-sm"> <strong>{t('Time')}:</strong> {timeelapsed || "0 Hours 0 Minutes"} </p>
          <p className="text-muted-foreground italic text-sm"> <strong>{t('Last')}:</strong> {last_date_updated || new Date().toLocaleString()} </p>
        </div>
      </div>
    </div>
  );
};

Telemetrics.propTypes = {
  current_humidity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  current_temperature: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  current_dewpoint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  current_amperage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  current_power: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  current_energy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  timeelapsed: PropTypes.string,
  last_date_updated: PropTypes.string,
  airmovers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dehumidifiers: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Telemetrics.defaultParams = {};

export default Telemetrics;
