"use client"

import { MoreHorizontal } from "lucide-react"
import { Button } from "@/components/ui/button"
import { StatusLight } from "../../UI/StatusLight"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

export const columns = ({ t, contractor_id, changeStatusF }) => [
  {
    accessorKey: "aname",
    header: t("Name"),
    enableSorting: true,
    cell: ({ row }) => {
      const area = row.original
      return (
        <div 
          onClick={() => window.location.href = "/daily/" + area.job_id + "/" + area.id}
          className="w-[280px] min-w-[280px] max-w-[280px]"
        >
          <p className="text-base font-semibold truncate">
            {area.aname}
          </p>
          <p className="text-sm font-normal text-muted-foreground truncate">
            {area.description}
          </p>
          {contractor_id !== null && (
            <p className="text-sm font-normal text-muted-foreground">
              {area.job_id}{area.id}
            </p>
          )}
        </div>
      )
    },
  },
  {
    accessorKey: "status",
    header: t("Status"),
    enableSorting: true,
    cell: ({ row }) => {
      const area = row.original
      let status = '';
      let label = '';

      switch (area.status) {
        case 'N':
          if (area.name_raspi === 'UNASSIGNED') {
            status = 'paused';
            label = 'Unassigned';
          } else {
            status = 'newarea';
            label = 'New';
          }
          break;
        case 'P':
          status = 'paused';
          label = 'Paused';
          break;
        case 'F':
          status = 'finished';
          label = 'Finished';
          break;
        case 'I':
          if (area.name_raspi === 'UNASSIGNED') {
            status = 'paused';
            label = 'Unassigned';
          } else {
            status = 'progress';
            label = 'In progress';
          }
          break;
        case 'T':
          status = 'paused';
          label = 'Fail Thermo';
          break;
        default:
          status = 'paused';
          label = 'Fail Amperage';
          break;
      }

      return (
        <div 
          onClick={() => window.location.href = "/daily/" + area.job_id + "/" + area.id}
          className="flex flex-row items-center text-base font-medium"
        >
          <StatusLight status={status} disabled={false} />
          {label}
        </div>
      )
    },
  },
  {
    accessorKey: "time_breakdown",
    header: t("TimeBreakdown"),
    enableSorting: false,
    cell: ({ row }) => {
      const area = row.original
      return (
        <div 
          onClick={() => window.location.href = "/daily/" + area.job_id + "/" + area.id}
          className="text-sm font-normal text-muted-foreground flex flex-column items-start"
        >
          <div>
            {t('ElapsedTime')}: {area.claim_time !== null ? parseFloat(area.claim_time).toFixed(2) : 0}h
          </div>
          <div>
            {t('DemoTime')}: {area.area_time !== null ? parseFloat(area.area_time - area.drying_time).toFixed(2) : 0}h
          </div>
          <div>
            {t('DryingTime')}: {area.drying_time !== null ? parseFloat(area.drying_time).toFixed(2) : 0}h
          </div>
        </div>
      )
    },
  },
  {
    accessorKey: "drying_setup",
    header: t("DryingSetup"),
    enableSorting: false,
    cell: ({ row }) => {
      const area = row.original
      return (
        <div onClick={() => window.location.href = "/daily/" + area.job_id + "/" + area.id}>
          <div className="flex flex-column items-start">
            <p className="text-base font-medium">
              {area.humisetpoint}{t('TargetHR')}
            </p>
          </div>
          <div className="text-sm font-normal text-muted-foreground">{area.dh} {t('Dehumidifier')}</div>
          <div className="text-sm font-normal text-muted-foreground">{area.bl} {t('AirMover')}</div>
        </div>
      )
    },
  },
  {
    accessorKey: "robbie_id",
    header: t("RobbieId"),
    enableSorting: true,
    cell: ({ row }) => {
      const area = row.original
      return (
        <div 
          onClick={() => window.location.href = "/daily/" + area.job_id + "/" + area.id}
          className="row-data-container"
        >
          <div className="flex flex-row items-center text-base font-medium">
            {
              area.status === 'F' ?
                <StatusLight status='finished' disable={false} />
                : area.is_ws_connected === 1 || area.is_ws_connected === true ?
                  <StatusLight status='progress' disabled={false} />
                  :
                  <StatusLight status='paused' disabled={false} />
            }
            {area.name_raspi}
          </div>
        </div>
      )
    },
  },
  {
    id: "actions",
    enableSorting: false,
    cell: ({ row }) => {
      const area = row.original

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">{t("OpenMenu")}</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t('Actions')}</DropdownMenuLabel>
            {(area.status === 'I' || area.status === 'F' || area.status === 'P') && contractor_id !== null && (
              <>
                <DropdownMenuItem onClick={() => window.location.href = "/daily/" + area.job_id + "/" + area.id}>
                  {t('DetailsLogs')}
                </DropdownMenuItem>
              </>
            )}
            {area.status !== 'F' && contractor_id !== null && (
              <>
                <DropdownMenuItem onClick={() => window.location.href = "/earea/" + area.id}>
                  {t('EditArea')}
                </DropdownMenuItem>
              </>
            )}
            {area.status === "P" && contractor_id !== null && (
              <DropdownMenuItem onClick={() => changeStatusF("I", area.job_id, area.id)}>
                {t('UnpauseArea')}
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
] 