import React, { useState, useMemo, useCallback } from "react"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { Loading } from "../UI/Loading";
import { useTranslation } from "react-i18next";
import { DataTable } from "./table/data-table";
import { StatusLight } from "../UI/StatusLight";
import Allfetch from '../Allfetch';
import { useAuth } from "@clerk/clerk-react";
import ip from "../../config_ip";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";

const contractor_id = localStorage.getItem('contractor_id')
const name = localStorage.getItem('name')

export default function Lgenareas() {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('status');
  const [sortOrder, setSortOrder] = useState('asc');
  const [statusR, setStatusR] = useState([]);
  const { getToken } = useAuth();

  const areas = useAllFetch(['areas'], '/lareas')
  
  const changeStatusF = useCallback(async (status, jobid, areaid) => {
    if (status === 'I') {
      const tokenu = await getToken()
      await fetch(ip.url + "/events", {
        method: "post",
        body: JSON.stringify({ areaid: areaid, why: "Restart area", user: name, type: "R" }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
    }
    Allfetch('/changeStatus/' + status + '/' + jobid + '/' + areaid, setStatusR, null, null, await getToken())
    if (statusR !== undefined) {
      window.location.pathname = "/jobs/" + contractor_id
    }
  }, [getToken, statusR]);

  // Calculate totals
  let total_bl = 0;
  let total_dh = 0;

  if (!areas.isPending && areas.data) {
    areas.data.forEach((area) => {
      total_bl += parseInt(area.blowers) || 0;
      total_dh += parseInt(area.dh) || 0;
    });
  }

  // Filter and sort data client-side
  const filteredAreas = useMemo(() => {
    if (!areas.data) return [];
    
    // Create a copy of the data to avoid mutating the original
    let processedData = [...areas.data];
    
    // Apply search filter if search term exists
    if (search.trim()) {
      const searchLower = search.toLowerCase();
      processedData = processedData.filter(area => {
        return (
          area.address?.toLowerCase().includes(searchLower) ||
          area.areadesc?.toLowerCase().includes(searchLower) ||
          area.name_raspi?.toLowerCase().includes(searchLower) ||
          area.name?.toLowerCase().includes(searchLower)
        );
      });
    }
    
    // Apply sorting
    processedData.sort((a, b) => {
      if (sortBy === 'status') {
        // First sort by status
        if (a.status !== b.status) {
          // For ascending order (In Progress first)
          if (sortOrder === 'asc') {
            // N (In Progress) comes before F (Finished)
            if (a.status === 'N' && b.status === 'F') return -1;
            if (a.status === 'F' && b.status === 'N') return 1;
            if (a.status === 'I' && b.status === 'F') return -1;
            if (a.status === 'F' && b.status === 'I') return 1;
            if (a.status === 'P' && b.status === 'F') return -1;
            if (a.status === 'F' && b.status === 'P') return 1;
            return a.status.localeCompare(b.status);
          } else {
            // For descending order (Finished first)
            if (a.status === 'N' && b.status === 'F') return 1;
            if (a.status === 'F' && b.status === 'N') return -1;
            if (a.status === 'I' && b.status === 'F') return 1;
            if (a.status === 'F' && b.status === 'I') return -1;
            if (a.status === 'P' && b.status === 'F') return 1;
            if (a.status === 'F' && b.status === 'P') return -1;
            return b.status.localeCompare(a.status);
          }
        }
        
        // If status is the same, sort by areaid (greater to lesser)
        return b.areaid - a.areaid;
      } else if (sortBy === 'areaid') {
        return sortOrder === 'desc' ? b.areaid - a.areaid : a.areaid - b.areaid;
      } else if (sortBy === 'address') {
        return sortOrder === 'asc' 
          ? a.address?.localeCompare(b.address || '') 
          : b.address?.localeCompare(a.address || '');
      } else if (sortBy === 'robbie_id') {
        return sortOrder === 'asc'
          ? (a.name_raspi || '').localeCompare(b.name_raspi || '')
          : (b.name_raspi || '').localeCompare(a.name_raspi || '');
      }
      
      // Default sort by areaid (greater to lesser)
      return b.areaid - a.areaid;
    });
    
    return processedData;
  }, [areas.data, search, sortBy, sortOrder]);

  // Create table columns with the necessary props
  const tableColumns = useMemo(() => [
    {
      accessorKey: "address",
      header: t("Name"),
      enableSorting: true,
      cell: ({ row }) => {
        const area = row.original
        return (
          <div className="w-[280px] min-w-[280px] max-w-[280px]">
            <p className="text-base font-semibold truncate">
              {area.address.split(',')[0]}
            </p>
            <p className="text-sm font-normal text-muted-foreground truncate">
              {area.areadesc}
            </p>
            {contractor_id !== null && (
              <p className="text-sm font-normal text-muted-foreground">
                {area.jobid}{area.areaid}
              </p>
            )}
          </div>
        )
      },
    },
    {
      accessorKey: "status",
      header: t("Status"),
      enableSorting: true,
      cell: ({ row }) => {
        const area = row.original
        return (
          <div className="inline">
            {area.status === 'N' ? (
              <div className="inline"><StatusLight status='newarea' disabled={false} />{t("New")}</div>
            ) : area.status === 'P' ? (
              <div className="inline"><StatusLight status='paused' disabled={false} />{t("Paused")}</div>
            ) : area.status === 'F' ? (
              <div className="inline"><StatusLight status='finished' disabled={false} />{t("Finished")}</div>
            ) : area.status === 'I' ? (
              <div className="inline"><StatusLight status='progress' disabled={false} />{t("In progress")}</div>
            ) : area.status === 'T' ? (
              <div className="inline"><StatusLight status='paused' disabled={false} />{t("Fail Thermo")}</div>
            ) : (
              <div className="inline"><StatusLight status='paused' disabled={false} />{t("Fail Amperage")}</div>
            )}
          </div>
        )
      },
    },
    {
      accessorKey: "setup",
      header: t("DryingSetup"),
      enableSorting: false,
      cell: ({ row }) => {
        const area = row.original
        return (
          <div className="row-data-container">
            <div className="main_text">
              {area.humisetpoint}% {t("TargetHR")}
            </div>
            <br />
            <small>{area.dh} {t("Dehumidifier")}</small><br />
            <small>{area.blowers} {t("AirMover")}</small>
          </div>
        )
      },
    },
    {
      accessorKey: "contractor_name",
      header: t("Contractor"),
      enableSorting: true,
      cell: ({ row }) => {
        const area = row.original
        return (
          <div className="row-data-container">
            <div className="main_text">
              {area.name}
            </div>
          </div>
        )
      },
    },
    {
      accessorKey: "robbie_id",
      header: t("RobbieId"),
      enableSorting: true,
      cell: ({ row }) => {
        const area = row.original
        return (
          <div className="row-data-container">
            <div className="main_text">
              {area.status === 'I'
                ? area.is_ws_connected === 1 || area.is_ws_connected === true ?
                    <StatusLight status='progress' disabled={false} />
                  :
                    <StatusLight status='paused' disabled={false} />
                : null
              }
              {area.name_raspi}
            </div>
          </div>
        )
      },
    },
    {
      id: "actions",
      enableSorting: false,
      cell: ({ row }) => {
        const area = row.original

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">{t("OpenMenu")}</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{t('Actions')}</DropdownMenuLabel>
              {(area.status === 'I' || area.status === 'F' || area.status === 'P') && contractor_id !== null && (
                <>
                  <DropdownMenuItem onClick={() => window.location.href = "/daily/" + area.jobid + "/" + area.areaid}>
                    {t('DetailsLogs')}
                  </DropdownMenuItem>
                </>
              )}
              {area.status !== 'F' && contractor_id !== null && (
                <>
                  <DropdownMenuItem onClick={() => window.location.href = "/earea/" + area.areaid}>
                    {t('EditArea')}
                  </DropdownMenuItem>
                </>
              )}
              {area.status === "P" && contractor_id !== null && (
                <DropdownMenuItem onClick={() => changeStatusF("I", area.jobid, area.areaid)}>
                  {t('UnpauseArea')}
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ], [t, changeStatusF]);

  if (areas.isPending) {
    return <Loading />;
  }

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Status Areas</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <DataTable 
        columns={tableColumns} 
        data={filteredAreas}
        search={search}
        setSearch={setSearch}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        showNoResultsMessage={!filteredAreas.length}
        isLoading={areas.isPending}
        totalDh={total_dh}
        totalBl={total_bl}
      />
    </div>
  )
}
